import { useRouter } from 'next/navigation';
import {
	Context,
	FC,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState
} from 'react';

import { RouterInstance } from '../../../types';

import { RequestConfirmation, RouterBlockerContextValues } from './types';

export const RouterBlockerContext: Context<null | RouterBlockerContextValues> =
	createContext<null | RouterBlockerContextValues>(null);

type RouterBlockerProviderProps = {
	blocked?: boolean;
	requestConfirmation?: RequestConfirmation;
};

export const RouterBlockerContextProvider = ({
	blocked: initialBlocked = false,
	requestConfirmation: initialRequestConfirmation,
	children
}: PropsWithChildren<RouterBlockerProviderProps>) => {
	const router = useRouter();
	const [isRouterBlocked, setRouterBlocked] = useState(initialBlocked);
	const setShouldBlockRouter = useCallback<RouterBlockerContextValues['setShouldBlockRouter']>(
		(shouldBlock) => setRouterBlocked(shouldBlock),
		[]
	);

	const requestConfirmationRef = useRef(initialRequestConfirmation);
	const setRequestConfirmation = useCallback<
		RouterBlockerContextValues['setRequestConfirmation']
	>((newCallback) => {
		requestConfirmationRef.current = newCallback;
	}, []);

	const proxyRouter: RouterInstance = useMemo(
		() =>
			new Proxy(router, {
				get: (...args) => {
					if (isRouterBlocked && requestConfirmationRef.current) {
						return (...callArgs: Parameters<RouterInstance[keyof RouterInstance]>) =>
							requestConfirmationRef.current?.(() =>
								Reflect.get(...args)(...callArgs)
							);
					}

					return Reflect.get(...args);
				}
			}),
		[isRouterBlocked, router]
	);

	return (
		<RouterBlockerContext.Provider
			value={{
				isRouterBlocked: isRouterBlocked,
				setShouldBlockRouter,
				requestConfirmation: requestConfirmationRef.current,
				setRequestConfirmation,
				router: proxyRouter
			}}>
			{children}
		</RouterBlockerContext.Provider>
	);
};

export const useRouterBlockerContext = (): RouterBlockerContextValues => {
	const context = useContext(RouterBlockerContext);

	if (!context) {
		throw new Error('Missing router blocker context');
	}

	return context;
};

export const withRouterBlockerContextProvider =
	(providerProps: RouterBlockerProviderProps) =>
	<ComponentProps,>(Component: FC<ComponentProps>) => {
		return function RouterBlockerProviderWrapper(
			componentProps: ComponentProps & JSX.IntrinsicAttributes
		) {
			return (
				<RouterBlockerContextProvider {...providerProps}>
					<Component {...componentProps} />
				</RouterBlockerContextProvider>
			);
		};
	};
