import {
	ERecommendationState,
	Recommendation,
	RecommendationControlChange
} from '@kelvininc/node-client-sdk';
import {
	EIconName,
	ETagState,
	ISelectMultiOptions,
	ITagStatus
} from '@kelvininc/react-ui-components';
import { ERecommendationActionType, IRecommendationActionTypeMap } from '@kelvininc/types';
import { isEmpty, isNil } from 'lodash-es';

import { calculateTimezoneOffsetDate } from '../dates';
import { isNumber } from '../numbers';

import { getHumanReadableFormat } from './date';

export const getRecommendationStatusName = (status: ERecommendationState): string =>
	({
		[ERecommendationState.Pending]: 'Pending',
		[ERecommendationState.Accepted]: 'Accepted',
		[ERecommendationState.Rejected]: 'Rejected',
		[ERecommendationState.Expired]: 'Expired',
		[ERecommendationState.Error]: 'Error',
		[ERecommendationState.AutoAccepted]: 'Accepted'
	})[status];

export const getRecommendationStatusIconName = (status: ERecommendationState): EIconName =>
	({
		[ERecommendationState.Pending]: EIconName.DottedCircle,
		[ERecommendationState.Accepted]: EIconName.Success,
		[ERecommendationState.Rejected]: EIconName.CloseCircle,
		[ERecommendationState.Expired]: EIconName.CloseCircle,
		[ERecommendationState.Error]: EIconName.Error,
		[ERecommendationState.AutoAccepted]: EIconName.Success
	})[status];

export const RECOMMENDATIONS_STATUSES_DROPDOWN_OPTIONS: ISelectMultiOptions = {
	[ERecommendationState.Pending]: {
		value: ERecommendationState.Pending,
		label: getRecommendationStatusName(ERecommendationState.Pending)
	},
	[ERecommendationState.Accepted]: {
		value: ERecommendationState.Accepted,
		label: getRecommendationStatusName(ERecommendationState.Accepted)
	},
	[ERecommendationState.Rejected]: {
		value: ERecommendationState.Rejected,
		label: getRecommendationStatusName(ERecommendationState.Rejected)
	},
	[ERecommendationState.Expired]: {
		value: ERecommendationState.Expired,
		label: getRecommendationStatusName(ERecommendationState.Expired)
	}
};

export const getRecommendationStatusTagState = (status: ERecommendationState): ETagState =>
	({
		[ERecommendationState.Pending]: ETagState.Warning,
		[ERecommendationState.Accepted]: ETagState.Success,
		[ERecommendationState.Rejected]: ETagState.Error,
		[ERecommendationState.Expired]: ETagState.Unknown,
		[ERecommendationState.Error]: ETagState.Error,
		[ERecommendationState.AutoAccepted]: ETagState.Success
	})[status];

export const getRecommendationTagStatus = (status: ERecommendationState): ITagStatus => ({
	state: getRecommendationStatusTagState(status),
	icon: getRecommendationStatusIconName(status),
	label: getRecommendationStatusName(status)
});

export const getRecommendationsActionsCount = (recommendation: Recommendation): number => {
	let actionsCount = 0;

	if (recommendation.actions.controlChanges) {
		actionsCount += recommendation.actions.controlChanges.length;
	}

	return actionsCount;
};

export const hasRecommendationActionAnError = (
	recommendation: Recommendation,
	action: RecommendationControlChange
): boolean => {
	// Check if is a control change and it was created
	if (!isNil(action.controlChangeId)) {
		return false;
	}

	// Check if the state is `Error`
	return recommendation.state === ERecommendationState.Error;
};

export const isRecommendationActionCreated = (
	recommendation: Recommendation,
	action: RecommendationControlChange
): boolean => {
	// Check if is a control change and it was not created
	if (isNil(action.controlChangeId)) {
		return false;
	}

	// Check if the state is either `Accepted` or `Error`
	return wasRecommendationAccepted(recommendation);
};

export const getRecommendationActions = <T extends keyof IRecommendationActionTypeMap>(
	recommendation: Recommendation,
	type: T
): IRecommendationActionTypeMap[T][] => {
	switch (type) {
		case ERecommendationActionType.ControlChange:
			return (recommendation.actions?.controlChanges ??
				[]) as IRecommendationActionTypeMap[T][];

		default:
			throw new Error('Unknown recommendation type');
	}
};

export const getRecommendationsErrorMessages = (recommendation: Recommendation): string[] => {
	const { logs } = recommendation;

	// Remove the last error since it's the overall recommendation's error
	const reducedLogs = logs.slice(0, -1);

	const errors = reducedLogs.reduce<string[]>((accumulator, { state, message }) => {
		if (state === 'error' && !isEmpty(message)) {
			accumulator.push(message);
		}

		return accumulator;
	}, []);

	return errors;
};

export const buildUpdatedInformationText = (
	recommendationState: ERecommendationState,
	date: string
): string => `${getRecommendationStatusName(recommendationState)} at ${date}`;

export const isRecommendationStateAccepted = (recommendation: Recommendation): boolean => {
	return [ERecommendationState.Accepted, ERecommendationState.AutoAccepted].includes(
		recommendation.state
	);
};

export const wasRecommendationAccepted = (recommendation: Recommendation): boolean => {
	return (
		isRecommendationStateAccepted(recommendation) ||
		recommendation.state === ERecommendationState.Error
	);
};

export const areRecommendationActionsCreated = (recommendation: Recommendation): boolean => {
	const {
		actions: { controlChanges = [] }
	} = recommendation;
	const actions = [...controlChanges];

	return actions.every((action) => isRecommendationActionCreated(recommendation, action));
};

export const getRecommendationTimestampLabel = (status: ERecommendationState): string =>
	({
		[ERecommendationState.Accepted]: 'Accepted on:',
		[ERecommendationState.Rejected]: 'Rejected on:',
		[ERecommendationState.Expired]: 'Expired on:',
		[ERecommendationState.Pending]: 'Expires on:',
		[ERecommendationState.Error]: 'Error on:',
		[ERecommendationState.AutoAccepted]: 'Accepted on:'
	})[status];

export const getRecommendationFormattedTimestamp = (
	recommendation: Recommendation,
	timezoneOffset?: number
): string | undefined => {
	const { expirationDate, updated } = recommendation;

	if (
		recommendation.state === ERecommendationState.Expired ||
		recommendation.state === ERecommendationState.Pending
	) {
		if (!expirationDate) {
			return;
		}

		return isNumber(timezoneOffset)
			? getHumanReadableFormat(
					calculateTimezoneOffsetDate(expirationDate, timezoneOffset, 'minutes')
				)
			: getHumanReadableFormat(expirationDate);
	}

	if (!updated) {
		return;
	}

	return isNumber(timezoneOffset)
		? getHumanReadableFormat(calculateTimezoneOffsetDate(updated, timezoneOffset, 'minutes'))
		: getHumanReadableFormat(updated);
};
