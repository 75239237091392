// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function => {
	return (
		Object.prototype.toString.call(value) === '[object Function]' || typeof value === 'function'
	);
};

export const compose = <T>(
	func1: (...args: T[]) => T,
	...funcs: Array<(args: T) => T>
): ((...args: T[]) => T) => {
	const composed = funcs.reduce(
		(prevFunc, nextFunc) => (value: T) => nextFunc(prevFunc(value)),
		(value) => value
	);

	return (...args: T[]) => composed(func1(...args));
};
