import { useRouter } from 'next/navigation';
import { useContext } from 'react';

import { RouterInstance } from '../../../types';
import { RouterBlockerContext } from '../../contexts';

export const useBlockableRouter = (): RouterInstance => {
	const router = useRouter();
	const context = useContext(RouterBlockerContext);

	return context?.router ?? router;
};
