import { SelectedRange } from '@kelvininc/react-ui-components';
import { DateInput, ERelativeTimeRangeKey, IUIDropdownOptions } from '@kelvininc/types';

import dayjs from 'dayjs';

import { DurationUnitType } from 'dayjs/plugin/duration';

import { isNil } from 'lodash-es';

import {
	RELATIVE_TIME_OFFSETS,
	calculateOffsetDate,
	calculateTimezoneOffsetDate,
	formatDate,
	fromNow,
	isSameDay,
	isSameMonth,
	isSameYear,
	isToday,
	isTomorrow,
	isYesterday,
	newDate,
	newDuration,
	now
} from '../dates';

const TIME_FORMAT = 'H:mm:ss';
const DATE_FORMAT = 'D MMM';
const DATE_FORMAT_WITH_YEAR = 'D MMM YYYY';
const DATE_TIME_FORMAT = 'D MMM H:mm:ss';
const DATE_TIME_FORMAT_WITH_YEAR = 'D MMM YYYY H:mm:ss';
const TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';

//TODO change this decorators to a static class and use @decorators to validate for empty inputs
/**
 * Get decorated date from decorator function, covering for empty dates scenarios.
 * @param date - The date to be decorated.
 * @param decoratorFn - The decorator function to apply to the date.
 * @param emptyDatePlaceholder - The placeholder to use for empty dates.
 * @returns The decorated date or the empty date placeholder if the date is empty.
 */
export const getDateFromDecorator = (
	date: DateInput,
	decoratorFn: (date: DateInput) => string,
	emptyDatePlaceholder = 'N/A'
): string => {
	if (isNil(date) || !dayjs(date).isValid()) {
		return emptyDatePlaceholder;
	}

	return decoratorFn(date);
};

/**
 * Get date in time format.
 * @example "8:12:22"
 */
export function getTimeFormat(date: DateInput): string {
	return formatDate(date, TIME_FORMAT);
}

/**
 * Get date in time format with time ellapsed from now format.
 * @example "a few seconds ago", "a minute ago", "3 minutes ago", "2 hours ago", "8:12:22"
 */
export function getTimeFromNowFormat(date: DateInput): string {
	return fromNow(date, getTimeFormat);
}

/**
 * Get date in date format.
 * @example "Today", "Yesterday", "Tomorrow", "7 Jun", "25 Oct 2014"
 */
export function getDateFormat(date: DateInput): string {
	// check if it is today
	if (isToday(date)) {
		return 'Today';
	}

	// check if it is yesterday
	if (isYesterday(date)) {
		return 'Yesterday';
	}

	// check if it is tomorrow
	if (isTomorrow(date)) {
		return 'Tomorrow';
	}

	if (isSameMonth(date)) {
		return formatDate(date, 'dddd, MMM D');
	}

	// check if year is the same
	if (isSameYear(date, now())) {
		return formatDate(date, DATE_FORMAT);
	}

	return formatDate(date, DATE_FORMAT_WITH_YEAR);
}

/**
 * Get date in date format with time ellapsed from now format.
 * @example "a few seconds ago", "a minute ago", "5 minutes ago", "2 hours ago", "2 Fev", "6 Dez 2012"
 */
export function getDateFromNowFormat(date: DateInput): string {
	return fromNow(date, getDateFormat);
}

/**
 * Get date in datetime format.
 * @example "2 Fev 14:55:21", "6 Dez 2012 5:15:20"
 */
export function getDateTimeFormat(date: DateInput): string {
	// check if year is the same
	if (isSameYear(date, now())) {
		return formatDate(date, DATE_TIME_FORMAT);
	}

	return formatDate(date, DATE_TIME_FORMAT_WITH_YEAR);
}

/**
 * Get date in datetime format with time ellapsed from now format.
 * @example "a few seconds ago", "a minute ago", "4 minutes ago", "2 hours ago", "2 Fev 14:55:21", "6 Dez 2012 5:15:20"
 */
export function getDateTimeFromNowFormat(date: DateInput): string {
	return fromNow(date, getDateTimeFormat);
}

/**
 * Get date in a readable datetime format.
 * @example "a few seconds ago", "a minute ago", "18 minutes ago", "2 hours ago", "Yesterday at 12:55:41", "6 Dez 2012 at 5:15:20"
 */
export function getReadableDateTimeFormat(date: DateInput) {
	// check if it is today
	if (isToday(date)) {
		return getDateTimeFromNowFormat(date);
	}

	return `${getDateFormat(date)} at ${getTimeFormat(date)}`;
}

/**
 * Get date in timestamp format.
 * @example "2022-05-22 06:41:22"
 */
export function getTimestampFormat(date: DateInput): string {
	return formatDate(date, TIMESTAMP_FORMAT);
}

/**
 * Get date in timestamp format with offset in milliseconds.
 * @example "2022-05-22 06:41:22"
 */
export function getTimestampFormatWithOffset(
	date: DateInput,
	offset: number,
	unit: dayjs.ManipulateType = 'milliseconds'
): string {
	const timezoneDate = calculateTimezoneOffsetDate(date, offset, unit);
	return formatDate(timezoneDate, TIMESTAMP_FORMAT);
}

export function getFormatReadableRange(start?: DateInput, end?: DateInput): string {
	if (!start && !end) {
		return '';
	}

	const startDate = formatDate(start, DATE_FORMAT_WITH_YEAR);
	const startDateTime = formatDate(start, DATE_TIME_FORMAT_WITH_YEAR);
	const startTime = formatDate(start, TIME_FORMAT);
	const endDateTime = formatDate(end, DATE_TIME_FORMAT_WITH_YEAR);
	const endTime = formatDate(end, TIME_FORMAT);

	if (isSameDay(start, end)) {
		return `${startDate} | ${startTime} - ${endTime}`;
	}

	return `${startDateTime} - ${endDateTime}`;
}

/**
 * Get date in utc format.
 * @example "2013-02-04T22:44:30.652Z"
 */
export function getUTCFormat(date: DateInput): string {
	return newDate(date).toISOString();
}

/**
 * Get date in utc ISO 8601.
 * @example "2013-02-04T22:44:30.652"
 */
export function getIso8061Format(date: DateInput): string {
	return formatDate(date, 'YYYY-MM-DDTHH:mm:ss.SSS');
}

/**
 * Returns the name of the last n months.
 * @example n = 2, current month december => [October, November]
 */
export const getLastMonthsName = (n: number) => {
	const lastMonths = [];
	const dateNow = now();

	for (let i = n - 1; i >= 0; i--) {
		lastMonths.push(dateNow.subtract(i, 'month').format('MMMM'));
	}

	return lastMonths;
};

/**
 * Format date to storage format
 * @returns a datetime string with microseconds defined
 */
export function formatToRFC3339DateTimeUTC(date: string | number | Date): string {
	return new Date(date).toISOString().replace('Z', '000Z');
}

/**
 * Returns a start and end time according to a relative time range in utc.
 * @param key the relative time key
 * @param cursor the relative timestamp cursor
 * @returns an array with datetime strings
 */
export const getRelativeTimeRange = (
	key: ERelativeTimeRangeKey,
	cursor: string | number | Date = now().toISOString()
): [string, string] => {
	const { offset, unit } = RELATIVE_TIME_OFFSETS[key];

	let startDate: string;
	let endDate: string;

	if (offset < 0) {
		startDate = calculateOffsetDate(cursor, offset, unit).toISOString();
		endDate = cursor.toString();
	} else {
		endDate = calculateOffsetDate(cursor, offset, unit).toISOString();
		startDate = cursor.toString();
	}

	return [startDate, endDate];
};

/**
 * Returns the provided range in utc.
 * @range the absolute time range
 * @offset the timezone offset
 * @returns an array with datetime strings
 */
export const getAbsoluteTimeRange = (range: SelectedRange, offset = 0): [string, string] => {
	return range.map((date) => calculateOffsetDate(date, offset, 'minutes').toISOString()) as [
		string,
		string
	];
};

export const getRelativeTimeRangeName = (time: ERelativeTimeRangeKey): string =>
	({
		[ERelativeTimeRangeKey.Last_5_M]: 'Last 5 minutes',
		[ERelativeTimeRangeKey.Last_10_M]: 'Last 10 minutes',
		[ERelativeTimeRangeKey.Last_15_M]: 'Last 15 minutes',
		[ERelativeTimeRangeKey.Last_30_M]: 'Last 30 minutes',
		[ERelativeTimeRangeKey.Last_1_H]: 'Last 1 hour',
		[ERelativeTimeRangeKey.Last_6_H]: 'Last 6 hours',
		[ERelativeTimeRangeKey.Last_12_H]: 'Last 12 hours',
		[ERelativeTimeRangeKey.Last_24_H]: 'Last 24 hours',
		[ERelativeTimeRangeKey.Last_48_H]: 'Last 48 hours',
		[ERelativeTimeRangeKey.Last_72_H]: 'Last 72 hours',
		[ERelativeTimeRangeKey.Last_7_D]: 'Last 7 days',
		[ERelativeTimeRangeKey.Last_2_W]: 'Last 2 weeks',
		[ERelativeTimeRangeKey.Last_14_D]: 'Last 14 days',
		[ERelativeTimeRangeKey.Last_1_M]: 'Last 30 days',
		[ERelativeTimeRangeKey.Last_90_D]: 'Last 90 days',

		[ERelativeTimeRangeKey.Next_5_M]: 'Next 5 minutes',
		[ERelativeTimeRangeKey.Next_10_M]: 'Next 10 minutes',
		[ERelativeTimeRangeKey.Next_15_M]: 'Next 15 minutes',
		[ERelativeTimeRangeKey.Next_30_M]: 'Next 30 minutes',
		[ERelativeTimeRangeKey.Next_1_H]: 'Next 1 hour',
		[ERelativeTimeRangeKey.Next_6_H]: 'Next 6 hours',
		[ERelativeTimeRangeKey.Next_12_H]: 'Next 12 hours',
		[ERelativeTimeRangeKey.Next_24_H]: 'Next 24 hours',
		[ERelativeTimeRangeKey.Next_48_H]: 'Next 48 hours',
		[ERelativeTimeRangeKey.Next_72_H]: 'Next 72 hours',
		[ERelativeTimeRangeKey.Next_7_D]: 'Next 7 days',
		[ERelativeTimeRangeKey.Next_2_W]: 'Next 2 weeks',
		[ERelativeTimeRangeKey.Next_1_M]: 'Next 30 days'
	})[time];

export const buildRelativeTimeRangeDropdownOptions = (
	time: ERelativeTimeRangeKey[]
): IUIDropdownOptions => {
	return time.reduce<IUIDropdownOptions>((accumulator, key) => {
		accumulator[key] = {
			value: key,
			label: getRelativeTimeRangeName(key)
		};

		return accumulator;
	}, {});
};

export function getFormatReadableDuration(
	time: number,
	unit: DurationUnitType = 'seconds'
): string {
	const duration = newDuration(time, unit);

	if (duration.asDays() >= 1) {
		return duration.format('D[d]');
	} else if (duration.asHours() >= 1) {
		return duration.format('H[h]');
	} else if (duration.asMinutes() >= 1) {
		return duration.format('m[m]');
	} else {
		return duration.format('s[s]');
	}
}

export function getFormatRoundedDuration(time: number, unit: DurationUnitType = 'seconds'): number {
	const duration = newDuration(time, unit);

	if (duration.asDays() >= 1) {
		return Math.floor(duration.asDays()) * 24 * 60 * 60;
	} else if (duration.asHours() > 1) {
		return Math.floor(duration.asHours()) * 60 * 60;
	} else if (duration.asMinutes() > 1) {
		return Math.floor(duration.asMinutes()) * 60;
	} else {
		return time;
	}
}

/**
 * Format a date into the string "Wed, 25 Jan 2023, 16:20".
 * @param date - The date to be formatted.
 * @returns A string representing the formatted date.
 * @example getHumanReadableFormat("2023-01-25T16:20:00.000Z") // returns "Wed, 25 Jan 2023, 16:20"
 */
export const getHumanReadableFormat = (date: DateInput): string => {
	return dayjs(date).format('ddd, DD MMM YYYY, HH:mm');
};
