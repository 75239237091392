export enum EInlineFilterType {
	Entity = 'entity',
	Property = 'property',
	Parameter = 'parameter'
}

export interface IInlineFilterConfig {
	title?: string;
	name: string;
	type: EInlineFilterType;
	application?: string;
	multiple?: boolean;
}
