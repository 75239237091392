import { IKvHttpResponse } from '@kelvininc/node-client-sdk';

import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { isHttpError } from '../http';

export const throwHttpError = (error: IKvHttpResponse): Observable<never> => {
	return throwError(new Error(JSON.stringify(error)));
};

export const catchHttpError = <T, O>(
	selector: (err: IKvHttpResponse) => Observable<O>
): OperatorFunction<T, T | O> => {
	return catchError<T, Observable<O>>((error) => {
		if (isHttpError(error)) {
			return selector(error);
		}

		return throwError(error);
	});
};
