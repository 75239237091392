import { EHttpAdapterType, KelvinSDK } from '@kelvininc/node-client-sdk';
import { Session } from 'next-auth';

import { useRef } from 'react';

import { BaseAppSettings } from '../../types';

export type KelvinSDKConfigOptions = {
	appSettings?: BaseAppSettings;
	session?: Session | null;
};

export const useKelvinSDKConfig = ({ appSettings, session }: KelvinSDKConfigOptions) => {
	/**
	 * TODO: review this when recoil are removed from nextjs project
	 * Since Recoil selectors run before the useEffects we have the necessity
	 * to put this outside.
	 */
	const initializedRef = useRef(false);
	if (initializedRef.current || !appSettings || !session) {
		return;
	}

	initializedRef.current = true;

	KelvinSDK.initialize(
		{
			baseUrl: appSettings.api.url,
			sessionConfig: {
				accessToken: session.token.accessToken as string,
				refreshToken: session.token.refreshToken,
				expiresIn: session.token.expiresIn as number,
				date: session.token.date as number
			},
			authConfig: {
				clientId: appSettings.api.client,
				realm: appSettings.authentication.realm,
				url: `${appSettings.authentication.url}${appSettings.authentication.path}`
			}
		},
		EHttpAdapterType.Fetch
	);
};
