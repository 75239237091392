import {
	EWorkloadDownloadStatus,
	EWorkloadStatus,
	Workload,
	WorkloadItem
} from '@kelvininc/node-client-sdk';
import { EIconName, ETagState, ITagStatus } from '@kelvininc/react-ui-components';
import { isEmpty } from 'lodash-es';

export const getWorkloadStatusLabel = (status: EWorkloadStatus): string =>
	({
		[EWorkloadStatus.Deploying]: 'Deploying',
		[EWorkloadStatus.Downloading]: 'Downloading',
		[EWorkloadStatus.Failed]: 'Failed',
		[EWorkloadStatus.PendingApply]: 'Pending Apply',
		[EWorkloadStatus.PendingDeploy]: 'Pending Deploy',
		[EWorkloadStatus.PendingStart]: 'Pending Start',
		[EWorkloadStatus.PendingUpdate]: 'Pending Update',
		[EWorkloadStatus.PendingStop]: 'Pending Stop',
		[EWorkloadStatus.Ready]: 'Ready',
		[EWorkloadStatus.Received]: 'Received',
		[EWorkloadStatus.Running]: 'Running',
		[EWorkloadStatus.Starting]: 'Starting',
		[EWorkloadStatus.Stopped]: 'Stopped',
		[EWorkloadStatus.Stopping]: 'Stopping',
		[EWorkloadStatus.Unreachable]: 'Unreachable',
		[EWorkloadStatus.Staged]: 'Pending Deploy'
	})[status];

export const getWorkloadStatusIconName = (status: EWorkloadStatus): EIconName =>
	({
		[EWorkloadStatus.Deploying]: EIconName.SyncCircle,
		[EWorkloadStatus.Downloading]: EIconName.SyncCircle,
		[EWorkloadStatus.Failed]: EIconName.Error,
		[EWorkloadStatus.PendingApply]: EIconName.DottedCircle,
		[EWorkloadStatus.PendingDeploy]: EIconName.DottedCircle,
		[EWorkloadStatus.PendingUpdate]: EIconName.DottedCircle,
		[EWorkloadStatus.PendingStart]: EIconName.DottedCircle,
		[EWorkloadStatus.PendingStop]: EIconName.DottedCircle,
		[EWorkloadStatus.Ready]: EIconName.Success,
		[EWorkloadStatus.Received]: EIconName.Success,
		[EWorkloadStatus.Running]: EIconName.Success,
		[EWorkloadStatus.Starting]: EIconName.SyncCircle,
		[EWorkloadStatus.Stopped]: EIconName.CloseCircle,
		[EWorkloadStatus.Stopping]: EIconName.SyncCircle,
		[EWorkloadStatus.Unreachable]: EIconName.CloseCircle,
		[EWorkloadStatus.Staged]: EIconName.DottedCircle
	})[status];

export const getWorkloadStatusTagState = (status: EWorkloadStatus): ETagState =>
	({
		[EWorkloadStatus.Deploying]: ETagState.Info,
		[EWorkloadStatus.Downloading]: ETagState.Info,
		[EWorkloadStatus.Failed]: ETagState.Error,
		[EWorkloadStatus.PendingApply]: ETagState.Warning,
		[EWorkloadStatus.PendingDeploy]: ETagState.Warning,
		[EWorkloadStatus.PendingUpdate]: ETagState.Warning,
		[EWorkloadStatus.PendingStart]: ETagState.Warning,
		[EWorkloadStatus.PendingStop]: ETagState.Warning,
		[EWorkloadStatus.Ready]: ETagState.Success,
		[EWorkloadStatus.Received]: ETagState.Success,
		[EWorkloadStatus.Running]: ETagState.Success,
		[EWorkloadStatus.Starting]: ETagState.Info,
		[EWorkloadStatus.Stopped]: ETagState.Unknown,
		[EWorkloadStatus.Stopping]: ETagState.Info,
		[EWorkloadStatus.Unreachable]: ETagState.Unknown,
		[EWorkloadStatus.Staged]: ETagState.Warning
	})[status];

export const getWorkloadTagStatus = (status: EWorkloadStatus): ITagStatus => ({
	state: getWorkloadStatusTagState(status),
	icon: getWorkloadStatusIconName(status),
	label: getWorkloadStatusLabel(status)
});

export const getWorkloadDownloadStatusLabel = (status: EWorkloadDownloadStatus): string =>
	({
		[EWorkloadDownloadStatus.Ready]: 'Yes',
		[EWorkloadDownloadStatus.Processing]: 'Creating',
		[EWorkloadDownloadStatus.Pending]: 'No',
		[EWorkloadDownloadStatus.Downloading]: 'Yes',
		[EWorkloadDownloadStatus.Scheduled]: 'Preparing',
		[EWorkloadDownloadStatus.Failed]: 'Failed'
	})[status];

export const getWorkloadDownloadStatusIconName = (status: EWorkloadDownloadStatus): EIconName =>
	({
		[EWorkloadDownloadStatus.Ready]: EIconName.Success,
		[EWorkloadDownloadStatus.Processing]: EIconName.SyncCircle,
		[EWorkloadDownloadStatus.Pending]: EIconName.CloseCircle,
		[EWorkloadDownloadStatus.Downloading]: EIconName.Success,
		[EWorkloadDownloadStatus.Scheduled]: EIconName.DottedCircle,
		[EWorkloadDownloadStatus.Failed]: EIconName.Error
	})[status];

export const getWorkloadDownloadStatusTagState = (status: EWorkloadDownloadStatus): ETagState =>
	({
		[EWorkloadDownloadStatus.Ready]: ETagState.Success,
		[EWorkloadDownloadStatus.Processing]: ETagState.Info,
		[EWorkloadDownloadStatus.Pending]: ETagState.Unknown,
		[EWorkloadDownloadStatus.Downloading]: ETagState.Success,
		[EWorkloadDownloadStatus.Scheduled]: ETagState.Warning,
		[EWorkloadDownloadStatus.Failed]: ETagState.Error
	})[status];

export const getWorkloadDownloadStatus = (state: EWorkloadDownloadStatus): ITagStatus => ({
	state: getWorkloadDownloadStatusTagState(state),
	icon: getWorkloadDownloadStatusIconName(state),
	label: getWorkloadDownloadStatusLabel(state)
});

export const isWorkloadStagedForCreation = (workload: Workload | WorkloadItem): boolean =>
	workload.status.state === EWorkloadStatus.Staged && !isEmpty(workload.staged);

export const isWorkloadStagedForChange = (workload: Workload | WorkloadItem): boolean =>
	workload.status.state !== EWorkloadStatus.Staged && !isEmpty(workload.staged);

export const isWorkloadOperationStart = (status: EWorkloadStatus): boolean =>
	[
		EWorkloadStatus.PendingStop,
		EWorkloadStatus.Stopped,
		EWorkloadStatus.Stopping,
		EWorkloadStatus.Unreachable
	].includes(status);

export const isWorkloadOperationStop = (status: EWorkloadStatus): boolean =>
	[
		EWorkloadStatus.Deploying,
		EWorkloadStatus.Failed,
		EWorkloadStatus.PendingApply,
		EWorkloadStatus.PendingDeploy,
		EWorkloadStatus.PendingStart,
		EWorkloadStatus.Running,
		EWorkloadStatus.Starting,
		EWorkloadStatus.Received,
		EWorkloadStatus.Downloading
	].includes(status);

export const canWorkloadBeApplied = (status: EWorkloadStatus): boolean =>
	[EWorkloadStatus.Ready].includes(status);

export const isWorkloadOperationBlocked = (status: EWorkloadStatus): boolean =>
	[
		EWorkloadStatus.Deploying,
		EWorkloadStatus.Downloading,
		EWorkloadStatus.PendingApply,
		EWorkloadStatus.PendingDeploy,
		EWorkloadStatus.PendingStart,
		EWorkloadStatus.PendingStop,
		EWorkloadStatus.Received,
		EWorkloadStatus.Starting,
		EWorkloadStatus.Stopping,
		EWorkloadStatus.Unreachable
	].includes(status);
