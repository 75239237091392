import { ISelectMultiOptions, ISelectSingleOptions } from '@kelvininc/react-ui-components';
import {
	DefinedAs,
	IBuildDropdownGroupedOptions,
	IBuildDropdownOption,
	IBuildDropdownOptions,
	IGetSortedOptions,
	ISchemaFormDropdownOption,
	IUIDropdownOption,
	IUIDropdownOptions
} from '@kelvininc/types';
import { get, isEmpty, isFunction } from 'lodash-es';

import { isSubString } from '../strings';

import { NONE_SCHEMA_FORM_DROPDOWN_OPTION } from './config';

const buildDropdownOption = <T>({
	item,
	key,
	label,
	description,
	value,
	icon,
	customClass,
	customStyle
}: IBuildDropdownOption<T>): IUIDropdownOption => {
	const itemKey = get(item, key);
	const itemLabel = isFunction(label) ? label(item) : get(item, label);
	const itemValue = isFunction(value) ? value(item) : get(item, value);
	const itemDescription = isFunction(description)
		? description(item)
		: description && get(item, description);
	const itemIcon = isFunction(icon) ? icon(item) : icon && get(item, icon);
	const itemCustomClass = isFunction(customClass) ? customClass(item) : customClass;
	const itemCustomStyle = isFunction(customStyle) ? customStyle(item) : customStyle;

	return {
		value: itemValue ?? itemKey,
		label: itemLabel ?? itemKey,
		description: itemDescription,
		icon: itemIcon,
		customClass: itemCustomClass,
		customStyle: itemCustomStyle
	};
};

export const getSortedOptions = <T>({
	data,
	key,
	label,
	sortFn = (a: string, b: string) => a.localeCompare(b),
	sortAsc = true
}: IGetSortedOptions<T>): Array<T> => {
	return data.slice().sort((itemA, itemB) => {
		const itemAKey = get(itemA, key);
		const itemALabel = isFunction(label) ? label(itemA) : get(itemA, label);
		const itemBKey = get(itemB, key);
		const itemBLabel = isFunction(label) ? label(itemB) : get(itemB, label);

		const labelA = itemALabel ?? itemAKey;
		const labelB = itemBLabel ?? itemBKey;

		return sortAsc ? sortFn(labelA, labelB) : sortFn(labelB, labelA);
	});
};

export const sortDropdownOptions = (
	options: IUIDropdownOptions,
	sortFn = (a: string, b: string) => a.localeCompare(b),
	sortAsc = true
): IUIDropdownOptions =>
	Object.entries(options)
		.sort(([, a], [, b]) => {
			const labelA = a.label ? a.label : a.value;
			const labelB = b.label ? b.label : b.value;

			return sortAsc ? sortFn(labelA, labelB) : sortFn(labelB, labelA);
		})
		.reduce<IUIDropdownOptions>((acc, [optKey, value]) => {
			if (!isEmpty(value.options)) {
				value.options = sortDropdownOptions(value.options, sortFn, sortAsc);
			}
			acc[optKey] = value;
			return acc;
		}, {});

export const searchDropdownOptions = (
	term: string,
	options: ISelectSingleOptions | ISelectMultiOptions = {}
): IUIDropdownOptions => {
	return Object.keys(options).reduce<ISelectSingleOptions | ISelectMultiOptions>(
		(accumulator, key) => {
			const option = options[key];

			if (!isEmpty(option.options)) {
				const childrenMatches = searchDropdownOptions(term, option.options);

				if (!isEmpty(childrenMatches)) {
					accumulator[key] = {
						...option,
						options: childrenMatches
					};
				}

				return accumulator;
			}

			if (isSubString(term, option.label) || isSubString(term, option.value)) {
				accumulator[key] = option;
			}

			return accumulator;
		},
		{}
	);
};

export const buildDropdownOptionsFromArray = <T>({
	data,
	key,
	label,
	sorted = true,
	sortFn,
	sortAsc,
	...otherOptions
}: IBuildDropdownOptions<T>): IUIDropdownOptions => {
	const unSortedOptions = data.reduce<IUIDropdownOptions>((accumulator, element) => {
		const id = get(element, key);
		accumulator[id] = buildDropdownOption({
			item: element,
			key,
			label,
			...otherOptions
		});
		return accumulator;
	}, {});

	return sorted ? sortDropdownOptions(unSortedOptions, sortFn, sortAsc) : unSortedOptions;
};

export const buildDropdownGroupedOptionsFromArray = <T>({
	data,
	key,
	label,
	groupKey,
	groupLabel,
	groupValue,
	sorted = true,
	sortFn,
	sortAsc,
	groupSelectable = false,
	...otherOptions
}: IBuildDropdownGroupedOptions<T>): IUIDropdownOptions => {
	const unSortedOptions = data.reduce<IUIDropdownOptions>((accumulator, item) => {
		const itemGroupKey = get(item, groupKey);
		const itemGroupLabel = isFunction(groupLabel) ? groupLabel(item) : get(item, groupLabel);
		const itemGroupValue = isFunction(groupValue) ? groupValue(item) : get(item, groupValue);
		const itemSelectable = isFunction(groupSelectable)
			? groupSelectable(item)
			: groupSelectable;

		if (!accumulator[itemGroupKey]) {
			accumulator[itemGroupKey] = {
				label: itemGroupLabel ?? itemGroupKey,
				value: itemGroupValue ?? itemGroupKey,
				selectable: itemSelectable,
				options: {}
			};
		}

		const id = get(item, key);
		accumulator[itemGroupKey].options = {
			...accumulator[itemGroupKey].options,
			[id]: buildDropdownOption({
				item,
				key,
				label,
				...otherOptions
			})
		};

		return accumulator;
	}, {});

	return sorted ? sortDropdownOptions(unSortedOptions, sortFn, sortAsc) : unSortedOptions;
};

export const getSelectedDropdownOptions = (options: Record<string, boolean>): string[] =>
	Object.keys(options).filter((key) => options[key]);

export const buildSelectedDropdownOptions = (options: string[]): Record<string, boolean> =>
	options.reduce<Record<string, boolean>>((accumulator, option) => {
		accumulator[option] = true;

		return accumulator;
	}, {});

export const buildSchemaFormDropdownOptions = <T>(
	data: T[],
	key: DefinedAs<T, string>,
	label?: DefinedAs<T, string> | ((item: T) => string),
	addNone = false,
	sorted = false
): ISchemaFormDropdownOption[] => {
	const options: ISchemaFormDropdownOption[] = data.map<ISchemaFormDropdownOption>((item) => ({
		title: isFunction(label) ? label(item) : `${get(item, label ?? key)}`,
		const: get(item, key)
	}));

	if (sorted) {
		options.sort((a, b) => a.title.localeCompare(b.title));
	}

	if (addNone) {
		options.unshift(NONE_SCHEMA_FORM_DROPDOWN_OPTION);
	}

	return options;
};
