import { ETimeseriesRangeGetDataAgg } from '@kelvininc/node-client-sdk';
import { SelectorAggregation } from '@kelvininc/types';
import { round } from 'lodash-es';

const TIME_BUCKETS = [
	60, // 1 minute
	120, // 2 minutes
	300, // 5 minutes
	600, // 10 minutes
	900, // 15 minutes
	1800, // 30 minutes
	3600, // 1 hour
	7200, // 2 hours
	14400, // 4 hours
	28800, // 8 hours
	57600, // 16 hours
	86400, // 24 hours
	604800, // 1 week
	1209600, // 2 weeks
	2419200 // 1 month
];

const DEFAULT_POINT_MARKER_SIZE = 8;

const timebucketForHistoricData = (
	rangeInMSeconds: number,
	numberOfPoints: number
): number | undefined => {
	let timebucket;
	const rangeInSec = rangeInMSeconds / 1000;
	const calculatedTimeBucketInSeconds = round(rangeInSec / numberOfPoints, 0);

	TIME_BUCKETS.some((defaultTimeBucket) => {
		if (defaultTimeBucket > calculatedTimeBucketInSeconds) {
			return true;
		}

		timebucket = defaultTimeBucket;
		return false;
	});

	return timebucket;
};

export const getSelectorsAggregation = (
	startTime: number,
	endTime: number,
	linePlotWidth?: number,
	pointSize = DEFAULT_POINT_MARKER_SIZE
): SelectorAggregation => {
	if (linePlotWidth) {
		const maxNumberOfAggregatedItems = Math.ceil(linePlotWidth / pointSize);
		const timeBucketInSeconds = maxNumberOfAggregatedItems
			? timebucketForHistoricData(endTime - startTime, maxNumberOfAggregatedItems)
			: null;

		if (timeBucketInSeconds) {
			return {
				agg: ETimeseriesRangeGetDataAgg.Mean,
				timeBucket: timeBucketInSeconds
			};
		}
	}

	return { agg: ETimeseriesRangeGetDataAgg.None, timeBucket: undefined };
};
