export enum EDataTagSourceType {
	User = 'user',
	Application = 'application',
	ServiceAccount = 'serviceAccount'
}

export enum EDataTagContextType {
	Datastream = 'datastream',
	Application = 'application'
}

export enum EDataTagType {
	Period = 'period',
	Point = 'point'
}
