import { Params, buildQuery, deserializeParams, serializeParams } from '@kelvininc/tsutils';
import { isEmpty } from 'lodash-es';
import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

import { BrowserHistoryAction, QueryParamsFn } from './types';

export const useQueryParams: QueryParamsFn = <T extends Params<T>>(
	deserializer = deserializeParams<T>,
	serializer = serializeParams<T>
) => {
	const searchParams = useSearchParams();

	const updateParams = useCallback(
		(params: T, routerAction: BrowserHistoryAction = 'replaceState') => {
			const currentParams = deserializer(searchParams);
			const newParams = serializer({ ...currentParams, ...params });
			const newQueryParams = buildQuery(newParams);

			window.history[routerAction](null, '', newQueryParams);
		},
		[searchParams, deserializer, serializer]
	);

	const removeParam = useCallback(
		(paramKey: keyof T, routerAction: BrowserHistoryAction = 'replaceState') => {
			const currentParams = deserializer(searchParams);
			const { [paramKey]: _, ...otherParams } = currentParams;
			const newQueryParams = !isEmpty(otherParams)
				? buildQuery(serializeParams(otherParams))
				: '';

			window.history[routerAction](null, '', newQueryParams);
		},
		[deserializer, searchParams]
	);

	return {
		searchParams,
		updateParams,
		removeParam
	};
};
