import { EDataType, EDatastreamExtraFieldComputationDataAgg } from '@kelvininc/node-client-sdk';

export enum EDataStreamCustomAgg {
	Last = 'last'
}

export type DataStreamAggregationFunction =
	| EDatastreamExtraFieldComputationDataAgg
	| EDataStreamCustomAgg;

export type DatastreamDataTypeValue = string | number | boolean;

export interface IDataStreamAssetContext {
	assetName: string;
	datastreamName: string;
	writable: boolean;
}

export interface IDatastreamDataTypeValues extends Record<EDataType, DatastreamDataTypeValue> {
	[EDataType.Object]: string;
	[EDataType.Boolean]: boolean;
	[EDataType.Number]: number;
	[EDataType.String]: string;
}

export enum EDataStreamTypes {
	RawBoolean = 'raw.boolean',
	RawFloat32 = 'raw.float32',
	RawFloat64 = 'raw.float64',
	RawInt32 = 'raw.int32',
	RawUint32 = 'raw.uint32',
	RawText = 'raw.text',
	Number = 'number',
	String = 'string',
	Boolean = 'boolean',
	Object = 'object'
}
