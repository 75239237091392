import { Model } from '@kelvininc/node-client-sdk';

import { get, isDate, isString } from 'lodash-es';

import { compareDates } from '../dates';
import { compareStrings } from '../strings';

export const modelsComparator = <T extends Model>(
	modelA: T,
	modelB: T,
	sortBy: keyof T,
	asc: boolean
): number => {
	const propertyA = get(modelA, sortBy);
	const propertyB = get(modelB, sortBy);

	if (isDate(propertyA) && isDate(propertyB)) {
		return compareDates(propertyA, propertyB, asc);
	}

	if (isString(propertyA) && isString(propertyB)) {
		return compareStrings(propertyA, propertyB, asc);
	}

	return 0;
};

export const sortModels = <T extends Model>(
	models: T[],
	sortBy: keyof T,
	asc = true,
	comparator: (modelA: T, modelB: T, sortBy: keyof T, asc: boolean) => number = modelsComparator
): T[] => models.slice().sort((modelA, modelB) => comparator(modelA, modelB, sortBy, asc));
