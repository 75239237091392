import { DefinedAs } from '@kelvininc/types';
import { isBoolean, isEmpty, isEqual, isFinite } from 'lodash-es';

import { isSubString } from '../strings';

export const getSelectedKeys = (mapObject: Record<string, boolean>): string[] =>
	Object.keys(mapObject).filter((key) => mapObject[key]);

export const toPlainObject = <T>(obj: T) => JSON.parse(JSON.stringify(obj)) as T;

export const filterObject = <T>(
	data: Record<string | number, T>,
	filter: (key: string, value: T) => boolean
): Record<string | number, T> => {
	return Object.entries(data).reduce<Record<string, T>>((acc, [key, value]) => {
		if (filter(key, value)) acc[key] = value;

		return acc;
	}, {});
};

export const isSomeValueNil = <K extends {}>(params: K): boolean => {
	return Object.values(params).some(
		(value) => value === undefined || value === null || value === ''
	);
};

export const areObjectPropertiesEmpty = <K extends {}>(params?: K): boolean => {
	if (!params) return false;

	return Object.values(params).every((item) => {
		if (isFinite(item) || isBoolean(item)) return false;

		return isEmpty(item);
	});
};

export const searchObjects = <T extends {}>(
	terms: string[],
	keys: DefinedAs<T, string>[],
	data: T[]
): T[] => {
	if (terms.length === 0) {
		return data;
	}

	if (keys.length === 0) {
		return data;
	}

	return data.filter((item) =>
		keys.some((key) =>
			terms.some((term) => {
				const value = item[key] as string | undefined;
				if (!value) {
					return false;
				}

				return isSubString(term, value);
			})
		)
	);
};

export const isSameComponentProps = <T>(prevProps: T, nextProps: T) =>
	isEqual(JSON.stringify(prevProps), JSON.stringify(nextProps));
