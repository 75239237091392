import { URLSearchParamsInit } from '@kelvininc/types';
import qs, { ParsedQuery } from 'query-string';

import urlJoin from 'url-join';

export const buildUrl = (parts: string | string[], searchParams?: URLSearchParamsInit): string => {
	const joinedUrl = urlJoin(...(Array.isArray(parts) ? parts : [parts]));

	if (searchParams) {
		const params = new URLSearchParams(searchParams as URLSearchParams);

		if (params.size > 0) {
			return `${joinedUrl}?${params}`;
		}
	}
	return joinedUrl;
};

export const getCurrentLocationBaseUrl = (): string => {
	return `${window.location.protocol}//${window.location.host}`;
};

export const joinQueryParams = (...paramsArray: URLSearchParamsInit[]) => {
	const mergedParams = new URLSearchParams();

	paramsArray.forEach((params) => {
		const searchParams = new URLSearchParams(params as URLSearchParams);
		searchParams.forEach((value, key) => {
			mergedParams.append(key, value);
		});
	});

	return mergedParams.toString();
};

export const appendQuery = (baseroute: string, query?: URLSearchParamsInit): string => {
	return buildUrl(baseroute, query);
};

export function buildQuery(values: URLSearchParamsInit): string {
	const params = new URLSearchParams(values as URLSearchParams);

	return `?${params.toString()}`;
}

export const convertToSearchParams = (query: ParsedQuery<string | undefined>): URLSearchParams => {
	return new URLSearchParams(qs.stringify(query));
};

export const removeBasePath = (basePath: string, inputUrl: string): string => {
	if (!basePath || basePath.trim() === '/') {
		return inputUrl;
	}
	const url = inputUrl.trim();
	if (!url.startsWith('/') || url === '/') {
		return url;
	}

	if (url.startsWith(basePath)) {
		return url.substring(basePath.length) || '/';
	}
	return url;
};
