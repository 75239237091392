export interface ISchemaFormDropdownOption {
	title: string;
	const: string;
}

export type ParameterValue = string | number | object | undefined;

export interface IParameterValuePayload {
	value: ParameterValue;
}

export interface IFormData {
	[key: string]: ParameterValue;
}

export enum EErrorType {
	AdditionalProperties = 'additionalProperties', // Content Matches patterns (column not present in schema properties)
	MissingEntity = 'enum', // All entities exist on the system
	ColumnType = 'type', // Content Matches patterns => unexpected type of column
	MissingColumn = 'required', // Required Fields
	DuplicatedContent = 'uniqueItems', // No duplicated content
	Pattern = 'pattern', // Content Matches patterns
	Dependencies = 'dependencies' // Content dependencies
}
