import bytes from 'bytes';
import { Duration } from 'dayjs/plugin/duration';
import ms from 'ms';

import { newDuration } from '../dates';

const booleanFalseValues = ['false', '0', 'no', 'off'];

export class Env {
	static getString(key: string, defaultValue = ''): string {
		return process.env[key] || defaultValue;
	}
	static getNumber(key: string, defaultValue = 0): number {
		const value = Env.getString(key);
		if (value) {
			return +value;
		}
		return defaultValue;
	}
	static getBoolean(key: string, defaultValue = false): boolean {
		const value = Env.getString(key);
		if (!value) {
			return defaultValue;
		}
		if (booleanFalseValues.includes(value)) {
			return false;
		}
		return true;
	}
	static getDuration(key: string, defaultValue = newDuration(0)): Duration {
		const value = Env.getString(key);
		if (!value) {
			return defaultValue;
		}
		const millis = ms(value);
		return newDuration(millis);
	}
	static getBytes(key: string, defaultValue = 0): number {
		const value = Env.getString(key);
		if (!value) {
			return defaultValue;
		}
		return bytes(value);
	}
}
