import { registerClass } from 'superjson';

type Class = { new (...args: unknown[]): unknown };

const isClass = (value: unknown): value is Class => {
	return typeof value === 'function' && !!value.prototype;
};

export const registerKelvinModelsInSuperjson = () =>
	import('@kelvininc/node-client-sdk/models').then((KelvinModels) => {
		Object.entries(KelvinModels).forEach(([className, exportedClass]) => {
			if (!isClass(exportedClass)) {
				return;
			}

			registerClass(exportedClass, className);
		});
	});
