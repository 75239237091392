import { isBoolean } from '../../booleans';
import { isNumber } from '../../numbers';
import { isString } from '../../strings';
import { ParamValidator, ParamValueValidator } from '../types';

export const defaultParamValueValidator = <V>(param: V): boolean => {
	if (isString(param) || isNumber(param) || isBoolean(param)) {
		if (param.toString().trim().length === 0) {
			return false;
		}

		return true;
	}

	throw new Error('Generic type should have a proper validator');
};

export const validateParamArray = <V>(
	param: V[],
	validator: ParamValueValidator<V> = defaultParamValueValidator
): boolean => {
	// find every parameter that doesn't meet the default validator rules
	const invalidValues = param.filter((value) => !validator(value));

	return invalidValues.length === 0;
};

export const defaultParamValidator = <V>(
	param: V,
	validator: ParamValueValidator<V> = defaultParamValueValidator
): boolean => {
	if (param === undefined) {
		return false;
	}

	if (Array.isArray(param)) {
		return validateParamArray(param, validator);
	}

	return validator(param);
};

export const isValidParamValue = <V>(
	param: V,
	validator: ParamValidator<V> = defaultParamValidator
): boolean => {
	return validator(param);
};
