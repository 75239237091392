import { URLSearchParamsInit } from '@kelvininc/types';

import { deserializeParam } from './deserializer';
import { serializeParam } from './serializer';

import {
	ParamValueSerialized,
	Params,
	ParamsDeserializeValidator,
	ParamsDeserializer,
	ParamsSerializeValidator,
	ParamsSerialized,
	ParamsSerializer
} from './types';

import { isValidParamValue } from './validators';

export const serializeParams = <T extends Params<T>>(
	params: T,
	serializer: ParamsSerializer<T> = {},
	validator: ParamsSerializeValidator<T> = {}
): URLSearchParamsInit => {
	return Object.keys(params).reduce((accumulator, key) => {
		const objKey = key as keyof T;
		const value = params[objKey];

		if (isValidParamValue(value, validator[objKey])) {
			const serializedValue = serializeParam(value, serializer[objKey]);

			if (serializedValue) {
				accumulator[objKey] = serializedValue;
			}
		}

		return accumulator;
	}, {} as ParamsSerialized<T>);
};

export const deserializeParams = <T extends Params<T>>(
	params: URLSearchParams,
	deserializer: ParamsDeserializer<T> = {},
	validator: ParamsDeserializeValidator<T> = {}
): T => {
	const deserializedParams = {} as T;

	for (const paramKey of params.keys()) {
		const values = params.getAll(paramKey);
		const key = paramKey as keyof T;

		let value: ParamValueSerialized = values;
		if (values.length === 1) {
			[value] = values;

			// URLSearchParams stores arrays as comma-separated strings
			// We need to convert them back to arrays
			if (value.includes(',')) {
				value = value.split(',');
			}
		}

		if (value && isValidParamValue(value, validator[key])) {
			const deserializedValue = deserializeParam(value, deserializer[key]);

			if (deserializedValue) {
				deserializedParams[key] = deserializedValue;
			}
		}
	}

	return deserializedParams;
};
