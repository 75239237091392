import { KvLoader } from '@kelvininc/react-ui-components';
import { buildUrl, removeBasePath } from '@kelvininc/tsutils';
import { URLSearchParamsInit } from '@kelvininc/types';
import { useRouter, useSearchParams } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';
import { FC, useLayoutEffect } from 'react';

import { AuthParams } from '../../../types';

import { isUnexpectedError } from './utils';

export type LoginPageProps = {
	baseUrl?: string;
	loginUrl: string;
};

export const LoginPage: FC<LoginPageProps> = ({ baseUrl = '/', loginUrl }) => {
	const { status } = useSession();
	const params = useSearchParams();
	const router = useRouter();

	useLayoutEffect(() => {
		if (status === 'loading') {
			return;
		}

		const callbackUrl = params.get(AuthParams.CallbackUrl);
		const error = params.get(AuthParams.Error);

		if (isUnexpectedError(error)) {
			const query: URLSearchParamsInit = { error };

			if (callbackUrl) {
				query[AuthParams.CallbackUrl] = callbackUrl;
			}

			const errorUrl = buildUrl('/error', query);
			router.replace(errorUrl);
		}

		if (status === 'unauthenticated') {
			if (callbackUrl) {
				signIn('keycloak', {
					callbackUrl: buildUrl([baseUrl, loginUrl], { callbackUrl })
				});
				return;
			}
			signIn('keycloak');
			return;
		}

		const baselessUrl = removeBasePath(baseUrl, callbackUrl || baseUrl);
		router.replace(baselessUrl);
	}, [params, status, baseUrl, loginUrl, router]);

	return <KvLoader isLoading />;
};
