export interface MetadataAuthInfo {
	logout_path: string;
	path: string;
	realm: string;
	url: string;
}

export interface MetadataAPIInfo {
	docs: string;
	path: string;
	url: string;
}

export interface MetadataCoreUiInfo {
	client_id: string;
	sentry_dsn: string;
}

export interface MetadataDocInfo {
	url: string;
}

export enum EMetadataSchemaKey {
	Api = 'core-api',
	Core = 'core',
	CoreUi = 'core-ui',
	Authentication = 'authentication',
	Documentation = 'documentation',
	Versions = 'versions'
}

export interface MetadataVersionsInfo {
	[EMetadataSchemaKey.Core]: string;
	[EMetadataSchemaKey.CoreUi]: string;
	[EMetadataSchemaKey.Api]: string;
}

export interface MetadataSchema {
	[EMetadataSchemaKey.Authentication]: MetadataAuthInfo;
	[EMetadataSchemaKey.Api]: MetadataAPIInfo;
	[EMetadataSchemaKey.Documentation]: MetadataDocInfo;
	[EMetadataSchemaKey.CoreUi]: MetadataCoreUiInfo;
	[EMetadataSchemaKey.Versions]: MetadataVersionsInfo;
}
