import {
	EParameterType,
	IParameterItem,
	IParameterValueUpdateData,
	ParameterDefinitionItem
} from '@kelvininc/node-client-sdk';

import { EIconName } from '@kelvininc/react-ui-components';
import {
	EClosedLoopMode,
	IClosedLoopSettings,
	IFormData,
	ValidAppPrimitiveType
} from '@kelvininc/types';
import { RJSFSchema } from '@rjsf/utils';

import { isBoolean } from '../../booleans';

import { isNumber } from '../../numbers';
import { isString } from '../../strings';

import { APPLICATION_CLOSED_LOOP_KEY } from './config';

export const buildParametersPayload = (
	formData: IFormData,
	note: string
): IParameterValueUpdateData[] | IParameterItem[] => {
	return Object.entries(formData).map(([key, value]) => ({
		comment: note,
		name: key,
		value: value
	}));
};

export const isParameterPrimitiveTypeString = (
	primitiveType: unknown
): primitiveType is EParameterType.RawText | EParameterType.String =>
	isString(primitiveType) &&
	[EParameterType.RawText, EParameterType.String].includes(primitiveType as EParameterType);

export const isParameterPrimitiveTypeBoolean = (
	primitiveType: unknown
): primitiveType is EParameterType.RawBoolean | EParameterType.Boolean =>
	isString(primitiveType) &&
	[EParameterType.RawBoolean, EParameterType.Boolean].includes(primitiveType as EParameterType);

export const isParameterPrimitiveTypeNumber = (
	primitiveType: unknown
): primitiveType is
	| EParameterType.RawUint32
	| EParameterType.RawInt32
	| EParameterType.RawFloat32
	| EParameterType.RawFloat64
	| EParameterType.Number =>
	isString(primitiveType) &&
	[
		EParameterType.RawUint32,
		EParameterType.RawInt32,
		EParameterType.RawFloat32,
		EParameterType.RawFloat64,
		EParameterType.Number
	].includes(primitiveType as EParameterType);

export const convertToParameterPrimitiveType = (
	value: unknown,
	primitiveType: EParameterType
): string | number | boolean => {
	if (isString(value)) {
		if (isParameterPrimitiveTypeBoolean(primitiveType)) {
			return value === 'true';
		}

		if (isParameterPrimitiveTypeNumber(primitiveType)) {
			return Number(value);
		}

		if (isParameterPrimitiveTypeString(primitiveType)) {
			return value;
		}
	}

	if (isBoolean(value)) {
		if (isParameterPrimitiveTypeBoolean(primitiveType)) {
			return value;
		}

		if (isParameterPrimitiveTypeNumber(primitiveType)) {
			return value ? 1 : 0;
		}

		if (isParameterPrimitiveTypeString(primitiveType)) {
			return value.toString();
		}
	}

	if (isNumber(value)) {
		if (isParameterPrimitiveTypeBoolean(primitiveType)) {
			return value === 1 ? true : false;
		}

		if (isParameterPrimitiveTypeNumber(primitiveType)) {
			return value;
		}

		if (isParameterPrimitiveTypeString(primitiveType)) {
			return value.toString();
		}
	}

	throw new Error(`Unsupported parameter value: ${typeof value}`);
};

export const getPrimitiveType = (primitiveType: string): ValidAppPrimitiveType | undefined => {
	if (isParameterPrimitiveTypeString(primitiveType)) {
		return EParameterType.String;
	}

	if (isParameterPrimitiveTypeNumber(primitiveType)) {
		return EParameterType.Number;
	}

	if (isParameterPrimitiveTypeBoolean(primitiveType)) {
		return EParameterType.Boolean;
	}
};

export const getParameterScheduleCalendarIcon = (hasRevertDate: string | boolean = false) => {
	if (hasRevertDate) {
		return EIconName.CalendarRange;
	}

	return EIconName.CalendarToday;
};

export const getParameterDefinitionTitle = (definition: ParameterDefinitionItem): string =>
	definition.lastTitle ?? definition.name;

export const getParameterTitle = (
	parameter: ParameterDefinitionItem,
	closedLoopSettings: IClosedLoopSettings
): string => {
	if (isKelvinClosedLoopParameter(parameter.name)) {
		return closedLoopSettings.displayLabel;
	}

	return getParameterDefinitionTitle(parameter);
};

export const buildClosedLoopDefinition = (closedLoopSettings: IClosedLoopSettings): RJSFSchema => {
	return {
		type: 'string',
		oneOf: Object.keys(closedLoopSettings.mapping).map((closeLoopKey) => ({
			title: closedLoopSettings.mapping[closeLoopKey as EClosedLoopMode],
			const: closeLoopKey
		}))
	};
};

export const isKelvinClosedLoopParameter = (paramName: string) =>
	paramName === APPLICATION_CLOSED_LOOP_KEY;
