import { ClassAttributes } from '@kelvininc/types';
import { get } from 'lodash-es';

export const convertToCSV = (data: unknown[][]): string => {
	return data.map((row) => row.join(',')).join('\n');
};

export const createCSVBlobFile = (csvData: string) =>
	new Blob([csvData], { type: 'text/csv;charset=utf-8' });

export const transformModelIntoCsvData = <T, P = ClassAttributes<T>>(
	data: T[],
	headers: { [key in keyof P | string]?: string },
	transformers: { [key in keyof P]?: (value: P[key]) => string } = {}
) => {
	const headerKeys = Object.keys(headers) as (keyof P)[];
	const headerTitles = Object.values(headers);

	const csvData = data.map((item) => {
		const rowData = headerKeys.map((key) => {
			const value = get(item, key) as P[keyof P];

			if (transformers[key]) {
				return transformers[key]!(value);
			}

			return value;
		});

		return rowData;
	});

	return [headerTitles, ...csvData];
};
