import { AppItem, EAppManagerAppStatus, EAppType } from '@kelvininc/node-client-sdk';

import {
	EIconName,
	ETagState,
	ISelectMultiOptions,
	ITagStatus
} from '@kelvininc/react-ui-components';

export const isKelvinApp = ({ type }: AppItem): boolean => type === EAppType.Kelvin;
export const isBridgeApp = ({ type }: AppItem): boolean => type === EAppType.Bridge;

export const getApplicationKey = (
	appName: string,
	appVersion?: string,
	assetName?: string
): string => `${appName}${appVersion ? `#${appVersion}` : ''}${assetName ? `#${assetName}` : ''}`;

export const fromApplicationKey = (appKey: string): string[] => appKey.split('#');

export const getApplicationStatusName = (status: EAppManagerAppStatus): string =>
	({
		[EAppManagerAppStatus.Updating]: 'Updating',
		[EAppManagerAppStatus.Running]: 'Running',
		[EAppManagerAppStatus.Stopped]: 'Stopped',
		[EAppManagerAppStatus.RequiresAttention]: 'Requires Attention'
	})[status];

export const getApplicationStatusIconName = (status: EAppManagerAppStatus): EIconName =>
	({
		[EAppManagerAppStatus.Updating]: EIconName.SyncCircle,
		[EAppManagerAppStatus.Running]: EIconName.Success,
		[EAppManagerAppStatus.Stopped]: EIconName.CloseCircle,
		[EAppManagerAppStatus.RequiresAttention]: EIconName.Error
	})[status];

export const getApplicationStatusTagState = (status: EAppManagerAppStatus): ETagState =>
	({
		[EAppManagerAppStatus.Updating]: ETagState.Info,
		[EAppManagerAppStatus.Running]: ETagState.Success,
		[EAppManagerAppStatus.Stopped]: ETagState.Unknown,
		[EAppManagerAppStatus.RequiresAttention]: ETagState.Error
	})[status];

export const getApplicationTagStatus = (status: EAppManagerAppStatus): ITagStatus => ({
	state: getApplicationStatusTagState(status),
	icon: getApplicationStatusIconName(status),
	label: getApplicationStatusName(status)
});

export const APPLICATION_STATUSES_DROPDOWN_OPTIONS: ISelectMultiOptions = {
	[EAppManagerAppStatus.Updating]: {
		value: EAppManagerAppStatus.Updating,
		label: getApplicationStatusName(EAppManagerAppStatus.Updating)
	},
	[EAppManagerAppStatus.Running]: {
		value: EAppManagerAppStatus.Running,
		label: getApplicationStatusName(EAppManagerAppStatus.Running)
	},
	[EAppManagerAppStatus.Stopped]: {
		value: EAppManagerAppStatus.Stopped,
		label: getApplicationStatusName(EAppManagerAppStatus.Stopped)
	},
	[EAppManagerAppStatus.RequiresAttention]: {
		value: EAppManagerAppStatus.RequiresAttention,
		label: getApplicationStatusName(EAppManagerAppStatus.RequiresAttention)
	}
};

export const APPLICATION_STATUSES_FILTER_OPTIONS = [
	{
		title: getApplicationStatusName(EAppManagerAppStatus.Updating),
		const: EAppManagerAppStatus.Updating
	},
	{
		title: getApplicationStatusName(EAppManagerAppStatus.Running),
		const: EAppManagerAppStatus.Running
	},
	{
		title: getApplicationStatusName(EAppManagerAppStatus.Stopped),
		const: EAppManagerAppStatus.Stopped
	},
	{
		title: getApplicationStatusName(EAppManagerAppStatus.RequiresAttention),
		const: EAppManagerAppStatus.RequiresAttention
	}
];
export const getApplicationTypeTitle = (app: AppItem): string =>
	({
		[EAppType.Kelvin]: 'Kelvin',
		[EAppType.Bridge]: 'Bridge',
		[EAppType.Docker]: 'Docker'
	})[app.type];
