import { get } from 'lodash-es';

export const getFileIdFromUrl = (url?: string) => {
	if (!url) {
		return;
	}
	const id = url.match(/\/filestorage\/(.*)\/download/);
	return get(id, [1]);
};

export const getFileUrl = (apiUrl: string, fileId: string): string | undefined => {
	if (!fileId || !apiUrl) {
		return;
	}

	return `${apiUrl}/filestorage/${fileId}/download`;
};
