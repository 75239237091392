import { EOrchestrationClusterStatus } from '@kelvininc/node-client-sdk';

import { EIconName, ETagState, ITagStatus } from '@kelvininc/react-ui-components';
import { EClusterRegisteredStatus } from '@kelvininc/types';

export const getClusterStatusLabel = (status: EOrchestrationClusterStatus): string =>
	({
		[EOrchestrationClusterStatus.Online]: 'Online',
		[EOrchestrationClusterStatus.Pending]: 'Pending',
		[EOrchestrationClusterStatus.PendingProvision]: 'Pending Provision',
		[EOrchestrationClusterStatus.RequiresAttention]: 'Requires Attention',
		[EOrchestrationClusterStatus.Unreachable]: 'Unreachable'
	})[status];

export const getClusterStatusIconName = (state: EOrchestrationClusterStatus): EIconName =>
	({
		[EOrchestrationClusterStatus.Online]: EIconName.Success,
		[EOrchestrationClusterStatus.Pending]: EIconName.DottedCircle,
		[EOrchestrationClusterStatus.PendingProvision]: EIconName.DottedCircle,
		[EOrchestrationClusterStatus.RequiresAttention]: EIconName.Error,
		[EOrchestrationClusterStatus.Unreachable]: EIconName.CloseCircle
	})[state];

export const getClusterStatusTagState = (state: EOrchestrationClusterStatus): ETagState =>
	({
		[EOrchestrationClusterStatus.Online]: ETagState.Success,
		[EOrchestrationClusterStatus.Pending]: ETagState.Warning,
		[EOrchestrationClusterStatus.PendingProvision]: ETagState.Warning,
		[EOrchestrationClusterStatus.RequiresAttention]: ETagState.Error,
		[EOrchestrationClusterStatus.Unreachable]: ETagState.Unknown
	})[state];

export const getClusterTagStatus = (status: EOrchestrationClusterStatus): ITagStatus => ({
	state: getClusterStatusTagState(status),
	icon: getClusterStatusIconName(status),
	label: getClusterStatusLabel(status)
});

const getClusterRegisteredStatusLabel = (status: EClusterRegisteredStatus): string =>
	({
		[EClusterRegisteredStatus.Pending]: 'Pending',
		[EClusterRegisteredStatus.Registered]: 'Yes'
	})[status];

export const getClusterTagRegisteredStatus = (ready: boolean): string =>
	getClusterRegisteredStatusLabel(
		ready ? EClusterRegisteredStatus.Registered : EClusterRegisteredStatus.Pending
	);

export const isRangeInsideOrNearCluster = (
	rangeStart: number,
	rangeEnd: number,
	clusterStart: number,
	clusterEnd: number
) =>
	(clusterEnd > rangeStart && clusterEnd <= rangeEnd) ||
	(clusterStart >= rangeStart && clusterStart < rangeEnd) ||
	(rangeStart > clusterStart && rangeEnd < clusterEnd);
