import { EOrchestrationNodeStatus } from '@kelvininc/node-client-sdk';
import { EIconName, ETagState, ITagStatus } from '@kelvininc/react-ui-components';

import { isEnumValue } from '../../strings';

export const getNodeStatusLabel = (status: EOrchestrationNodeStatus): string =>
	({
		[EOrchestrationNodeStatus.Online]: 'Online',
		[EOrchestrationNodeStatus.Unreachable]: 'Unreachable',
		[EOrchestrationNodeStatus.NotReady]: 'Not Ready'
	})[status];

export const getNodeStatusIconName = (state: EOrchestrationNodeStatus): EIconName =>
	({
		[EOrchestrationNodeStatus.Online]: EIconName.Success,
		[EOrchestrationNodeStatus.Unreachable]: EIconName.CloseCircle,
		[EOrchestrationNodeStatus.NotReady]: EIconName.CloseCircle
	})[state];

export const getNodeStatusTagState = (state: EOrchestrationNodeStatus): ETagState =>
	({
		[EOrchestrationNodeStatus.Online]: ETagState.Success,
		[EOrchestrationNodeStatus.Unreachable]: ETagState.Unknown,
		[EOrchestrationNodeStatus.NotReady]: ETagState.Unknown
	})[state];

export const getNodeTagStatus = (status?: EOrchestrationNodeStatus): ITagStatus => {
	if (!status || !isEnumValue(status, EOrchestrationNodeStatus)) {
		return {
			state: ETagState.Unknown,
			icon: EIconName.CloseCircle,
			label: 'Unknown'
		};
	}

	return {
		state: getNodeStatusTagState(status),
		icon: getNodeStatusIconName(status),
		label: getNodeStatusLabel(status)
	};
};

export const getNodeKey = (clusterName: string, nodeName: string): string =>
	`${clusterName}#${nodeName}`;

export const fromNodeKey = (nodeKey: string): string[] => nodeKey.split('#');
