import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { ComponentProps } from 'react';

import { RequestConfirmation, useRouterBlockerContext } from '../../contexts';

type LinkRouterBlocker = {
	isRouterBlocked?: boolean;
	requestConfirmation?: RequestConfirmation;
};

type NextLinkProps = {
	disabled?: boolean;
	href?: string;
} & Omit<ComponentProps<typeof Link>, 'href'> &
	LinkRouterBlocker;

/**
 * A wrapper around Next.js' `Link` component that adds a confirmation dialog when navigating away from the page.
 * Its only purpose is to be passed to the LinkContextProvider.
 *
 * @internal Use `Link` from `@kelvininc/shared-ui` instead.
 */
export const NextLink = ({
	href,
	onClick,
	disabled,
	prefetch,
	target,
	isRouterBlocked,
	requestConfirmation,
	...otherProps
}: NextLinkProps) => {
	const router = useRouter();
	const isPrefetching = prefetch ?? (target === '_blank' ? false : undefined);

	const onClickAnchor = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if (!event.isTrusted) {
			return;
		}

		const anchor = event.currentTarget;
		const isDownloadLink = anchor.download !== '';
		const isExternal = anchor.target === '_blank';

		if (isDownloadLink) {
			return;
		}

		if (isRouterBlocked && requestConfirmation) {
			event.preventDefault();
			event.stopPropagation();

			requestConfirmation(() => {
				if (isExternal) {
					anchor.dispatchEvent(new MouseEvent(event.type, event.nativeEvent));
				} else {
					router.push(anchor.href);
				}
			});
			return;
		}

		onClick?.(event);
	};

	if (!href) {
		return <span aria-disabled={disabled} onClick={onClick} {...otherProps} />;
	}

	return (
		<Link
			href={href}
			aria-disabled={disabled}
			onClick={onClickAnchor}
			prefetch={isPrefetching}
			target={target}
			{...otherProps}
		/>
	);
};

export const NextLinkBlocker = (props: NextLinkProps) => {
	const { requestConfirmation, isRouterBlocked } = useRouterBlockerContext();

	return (
		<NextLink
			{...props}
			requestConfirmation={requestConfirmation}
			isRouterBlocked={isRouterBlocked}
		/>
	);
};
