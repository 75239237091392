export const isExponential = (number: number): boolean => number.toString().includes('e');

export const isNumeric = (value: unknown): value is number =>
	typeof value === 'number' && !isNaN(value as number);

export const isNumberish = (value: unknown): boolean =>
	(typeof value === 'number' || (typeof value === 'string' && value.trim() !== '')) &&
	!isNaN(value as number);

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const getNumericValue = (value?: string) => (isNumberish(value) ? Number(value) : undefined);
