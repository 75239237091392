import { EKrnResource, IKrnResourceContentMap, KvKRNParser } from '@kelvininc/node-client-sdk';

export const getResourceContent = <T extends EKrnResource>(
	resource: string
): IKrnResourceContentMap[T] | null => {
	try {
		const { resourceContent } = KvKRNParser.parseKRN<T>(resource);
		return resourceContent as IKrnResourceContentMap[T];
	} catch (error) {
		console.warn(error);
		return null;
	}
};

export const getRecommendationSourceFromKRN = (recommendationSource: string): string => {
	try {
		const { resource, resourceContent } = KvKRNParser.parseKRN(recommendationSource);

		if (resource === EKrnResource.Workload) {
			const { cluster: clusterName, workload: workloadName } = resourceContent;

			return `${clusterName}/${workloadName}`;
		}

		if (resource === EKrnResource.WorkloadAppVersion) {
			const { app: appName, version: versionName } = resourceContent;

			return `${appName}/${versionName}`;
		}

		if (resource === EKrnResource.User) {
			const { user } = resourceContent;

			return user;
		}

		return recommendationSource;
	} catch (error) {
		return 'Invalid KRN';
	}
};
