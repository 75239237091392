import { KvLoader } from '@kelvininc/react-ui-components';
import { buildUrl } from '@kelvininc/tsutils';
import { useSearchParams } from 'next/navigation';
import { signOut, useSession } from 'next-auth/react';
import { FC, useLayoutEffect } from 'react';

import { AuthParams } from '../../../types';

export type LogoutPageProps = {
	baseUrl?: string;
	loginUrl: string;
};

export const LogoutPage: FC<LogoutPageProps> = ({ baseUrl = '/', loginUrl }) => {
	const { status } = useSession();
	const params = useSearchParams();

	useLayoutEffect(() => {
		if (status === 'loading') {
			return;
		}
		const callbackUrl = params.get(AuthParams.CallbackUrl);
		if (callbackUrl) {
			signOut({ callbackUrl: buildUrl([baseUrl, loginUrl], { callbackUrl }) });
			return;
		}
		signOut({ callbackUrl: loginUrl });
	}, [baseUrl, loginUrl, params, status]);

	return <KvLoader isLoading />;
};
