import {
	DateInput,
	ECalendarAdvanceTimeType,
	ERelativeTimeRangeKey,
	ITimeChange
} from '@kelvininc/types';
import { default as dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import durations, { DurationUnitType } from 'dayjs/plugin/duration';
import isTodayPlugin from 'dayjs/plugin/isToday';
import isTommorrowPlugin from 'dayjs/plugin/isTomorrow';
import isYesterdayPlugin from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DurationInput } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isTodayPlugin);
dayjs.extend(isTommorrowPlugin);
dayjs.extend(isYesterdayPlugin);
dayjs.extend(relativeTime);
dayjs.extend(durations);
dayjs.extend(advancedFormat);

export const RELATIVE_TIME_OFFSETS: Record<
	ERelativeTimeRangeKey,
	{ offset: number; unit: dayjs.ManipulateType }
> = {
	[ERelativeTimeRangeKey.Last_5_M]: {
		offset: -5,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Last_10_M]: {
		offset: -10,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Last_15_M]: {
		offset: -15,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Last_30_M]: {
		offset: -30,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Last_1_H]: {
		offset: -1,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Last_6_H]: {
		offset: -6,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Last_12_H]: {
		offset: -12,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Last_24_H]: {
		offset: -24,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Last_48_H]: {
		offset: -48,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Last_72_H]: {
		offset: -72,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Last_7_D]: {
		offset: -7,
		unit: 'days'
	},
	[ERelativeTimeRangeKey.Last_14_D]: {
		offset: -14,
		unit: 'days'
	},
	[ERelativeTimeRangeKey.Last_90_D]: {
		offset: -90,
		unit: 'days'
	},
	[ERelativeTimeRangeKey.Last_2_W]: {
		offset: -2,
		unit: 'weeks'
	},
	[ERelativeTimeRangeKey.Last_1_M]: {
		offset: -1,
		unit: 'months'
	},

	[ERelativeTimeRangeKey.Next_5_M]: {
		offset: 5,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Next_10_M]: {
		offset: 10,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Next_15_M]: {
		offset: 15,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Next_30_M]: {
		offset: 30,
		unit: 'minutes'
	},
	[ERelativeTimeRangeKey.Next_1_H]: {
		offset: 1,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Next_6_H]: {
		offset: 6,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Next_12_H]: {
		offset: 12,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Next_24_H]: {
		offset: 24,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Next_48_H]: {
		offset: 48,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Next_72_H]: {
		offset: 72,
		unit: 'hours'
	},
	[ERelativeTimeRangeKey.Next_7_D]: {
		offset: 7,
		unit: 'days'
	},
	[ERelativeTimeRangeKey.Next_2_W]: {
		offset: 2,
		unit: 'weeks'
	},
	[ERelativeTimeRangeKey.Next_1_M]: {
		offset: 1,
		unit: 'months'
	}
};

export const isDate = (date: unknown): date is Date =>
	typeof date === 'object' && date instanceof Date;

export const newDate = (date?: DateInput): dayjs.Dayjs => {
	return dayjs(date);
};

export const newTimezoneDate = (date: DateInput, offset: number): dayjs.Dayjs => {
	return dayjs.utc(date).utcOffset(offset);
};

export const newUtcDate = (date?: DateInput): dayjs.Dayjs => dayjs.utc(date);

export const now = (): dayjs.Dayjs => {
	return dayjs();
};

export const newDuration = (time: number, unit?: DurationUnitType): DurationInput =>
	dayjs.duration(time, unit);

export const isToday = (date: DateInput): boolean => {
	return newDate(date).isToday();
};

export const yesterday = (): dayjs.Dayjs => {
	return now().subtract(1, 'day');
};

export const isYesterday = (date: DateInput): boolean => {
	return newDate(date).isSame(yesterday(), 'day');
};

export const tomorrow = (): dayjs.Dayjs => {
	return now().add(1, 'day');
};

export const isTomorrow = (date: DateInput): boolean => {
	return newDate(date).isTomorrow();
};

export const isSameDay = (dateA: DateInput, dateB?: DateInput): boolean => {
	return newDate(dateA).isSame(dateB, 'day');
};

export const isSameWeek = (dateA: DateInput, dateB?: DateInput): boolean => {
	return newDate(dateA).isSame(dateB, 'week');
};

export const isSameMonth = (dateA: DateInput, dateB?: DateInput): boolean => {
	return newDate(dateA).isSame(dateB, 'month');
};

export const isSameYear = (dateA: DateInput, dateB?: DateInput): boolean => {
	return newDate(dateA).isSame(dateB, 'year');
};

export const isTimeLessThan = (date: DateInput, unit: dayjs.ManipulateType): boolean => {
	return newDate(date).isAfter(now().subtract(1, unit));
};

export const isTimeLongerThan = (date: DateInput, unit: dayjs.ManipulateType): boolean => {
	return newDate(date).isBefore(now().subtract(1, unit));
};

export const calculateOffsetDate = (
	time: DateInput,
	offset: number,
	unit: dayjs.ManipulateType
): Date => {
	return newDate(time).add(offset, unit).toDate();
};

export const calculateTimezoneOffsetDate = (
	time: DateInput,
	offset: number,
	unit: dayjs.ManipulateType
): DateInput => {
	return dayjs.utc(time).add(offset, unit);
};

export const convertTimeToMillis = (timeAmount: number, timeUnit: dayjs.ManipulateType): number => {
	return dayjs.duration(timeAmount, timeUnit).asMilliseconds();
};

export const formatDate = (date: DateInput, mask = 'D MMM YYYY'): string => {
	return newDate(date).format(mask);
};

export const fromNow = (date: DateInput, formatter: (date: DateInput) => string): string => {
	const clonnedDate = newDate(date);

	// check if it is less than a day
	if (isTimeLessThan(clonnedDate, 'day')) {
		return clonnedDate.fromNow();
	}

	return formatter(clonnedDate);
};

export const getDefaultTimezone = () => dayjs.tz.guess() ?? 'UTC';

export const getTimezoneOffset = (zone?: string) => dayjs().tz(zone).utcOffset();

export const isDateValid = (date: DateInput): boolean => {
	if (date === undefined) {
		return false;
	}

	return newDate(date).isValid();
};
export const getDatesRangeKey = (startDate: string | number, endDate: string | number): string =>
	`${startDate}#${endDate}`;

export const fromDatesRangeKey = (range: string): [number, number] => {
	const [startDate, endDate] = range.split('#').map(parseFloat);

	return [startDate, endDate];
};

export const getRandomDateBetween = (
	startDate: string | number,
	endDate: string | number
): dayjs.Dayjs => {
	const fromMilli = dayjs(startDate).valueOf();
	const max = dayjs(endDate).valueOf() - fromMilli;

	const dateOffset = Math.floor(Math.random() * max + 1);

	const randomDate = dayjs(fromMilli + dateOffset);

	return dayjs(randomDate);
};

export const getRandomSoonDate = (days = 1, referenceDate?: string | number) => {
	const from = dayjs(referenceDate);
	const to = from.add(days, 'day');

	return getRandomDateBetween(from.toISOString(), to.toISOString());
};

export const getRandomRecentDate = (days = 1, referenceDate?: string | number) => {
	const to = dayjs(referenceDate);
	const from = to.subtract(days, 'day');

	return getRandomDateBetween(from.toISOString(), to.toISOString());
};

export const getRandomFutureDate = (years = 1, referenceDate?: string | number) => {
	const from = dayjs(referenceDate);
	const to = from.add(years, 'year');

	return getRandomDateBetween(from.toISOString(), to.toISOString());
};

export const getRandomPastDate = (years = 1, referenceDate?: string | number) => {
	const to = dayjs(referenceDate);
	const from = to.subtract(years, 'year');

	return getRandomDateBetween(from.toISOString(), to.toISOString());
};

export const buildRelativeTimeRange = (timeRangeKey: string): Pick<ITimeChange, 'time'> => ({
	time: {
		type: ECalendarAdvanceTimeType.Relative,
		payload: {
			key: timeRangeKey,
			range: []
		}
	}
});

export const compareDates = (
	dateA: string | number | Date,
	dateB: string | number | Date,
	asc = true
): number => {
	const clonnedDateA = newDate(dateA);
	const clonnedDateB = newDate(dateB);

	const datesDiff = clonnedDateA.diff(clonnedDateB);

	if (!asc) {
		return datesDiff * -1;
	}

	return datesDiff;
};

export const calculateRangeFromNow = (
	duration: number,
	unit: dayjs.ManipulateType = 'hours'
): [number, number] => {
	const today = newDate().utc();

	return [today.subtract(duration, unit).valueOf(), today.valueOf()];
};

export const truncTimeToSec = (date: DateInput): number =>
	Math.trunc(newDate(date).valueOf() / 1000);
