import { EPropertyType, Property } from '@kelvininc/node-client-sdk';

import { isBoolean, isBooleanish } from '../booleans';

import { isDate } from '../dates';

import { isPropertyPrimitiveTypeBoolean, isPropertyPrimitiveTypeNumber } from '../models';
import { isNumber, isNumberish } from '../numbers';
import { isString } from '../strings';

export const inferPrimitiveType = (value: unknown): EPropertyType => {
	if (isBooleanish(value)) {
		return EPropertyType.Boolean;
	} else if (isNumberish(value)) {
		return EPropertyType.Number;
	} else {
		return EPropertyType.String;
	}
};

export const convertToPropertyPrimitiveType = (
	value: unknown,
	primitiveType: EPropertyType
): string | number | boolean => {
	if (isString(value)) {
		switch (primitiveType) {
			case EPropertyType.Boolean:
				return value === 'true';

			case EPropertyType.Number:
				return Number(value);

			case EPropertyType.String:
			case EPropertyType.Timestamp:
				return value;
		}
	}

	if (isBoolean(value)) {
		switch (primitiveType) {
			case EPropertyType.Boolean:
				return value;

			case EPropertyType.Number:
				return value ? 1 : 0;

			case EPropertyType.String:
				return value.toString();

			case EPropertyType.Timestamp:
				throw new Error('Cannot convert a boolean into a timestamp');
		}
	}

	if (isNumber(value)) {
		switch (primitiveType) {
			case EPropertyType.Boolean:
				return value === 1 ? true : false;

			case EPropertyType.Number:
				return value;

			case EPropertyType.String:
			case EPropertyType.Timestamp:
				return value.toString();
		}
	}

	if (isDate(value)) {
		switch (primitiveType) {
			case EPropertyType.Boolean:
				return true;

			case EPropertyType.Number:
				return value.getTime();

			case EPropertyType.String:
			case EPropertyType.Timestamp:
				return value.toString();
		}
	}

	throw new Error(`Unsupported property value: ${typeof value}`);
};

export const getPropertyValueValidator = ({
	primitiveType
}: Property): ((value: unknown) => boolean) => {
	if (isPropertyPrimitiveTypeNumber(primitiveType)) {
		return isNumberish;
	} else if (isPropertyPrimitiveTypeBoolean(primitiveType)) {
		return isBooleanish;
	}
	return () => true;
};
