import { sortBy } from 'lodash-es';

export const isArray = (array: unknown): array is [] => array !== undefined && Array.isArray(array);

export const hasDifferencesBetweenArrays = <T>(firstArray: T[], secondArray: T[]): boolean => {
	const hasDifferencesInFirstArray =
		firstArray.find((firstArrayElement) => !secondArray.includes(firstArrayElement)) !==
		undefined;

	if (hasDifferencesInFirstArray) {
		return true;
	}

	const hasDifferencesInSecondArray =
		secondArray.find((secondArrayElement) => !firstArray.includes(secondArrayElement)) !==
		undefined;

	return hasDifferencesInSecondArray;
};

export const sortArrayByGroupOfFields = <T>(
	unsortedArray: T[],
	fields: string[] | number[] | symbol[]
): T[] => {
	return sortBy(unsortedArray, fields);
};

export const isSubsetOf = <T>(subsetArray: Array<T>, parentList: Array<T>): boolean =>
	subsetArray.every((subsetItem) => parentList.includes(subsetItem));

export const updateArrayItem = <T>(array: T[], itemIndex: number, newItem: T): T[] => {
	const newArray = [...array];

	newArray.splice(itemIndex, 1, newItem);

	return newArray;
};

export const moveArrayItem = <T>(list: Array<T>, fromIndex: number, toIndex: number): Array<T> => {
	const newList = [...list];

	// Remove item from the index position
	const [removed] = newList.splice(fromIndex, 1);

	// Insert item at end index position
	newList.splice(toIndex, 0, removed);
	return newList;
};

export const addArrayItem = <T>(array: T[], itemIndex: number, newItem: T): T[] => {
	const newArray = [...array];

	newArray.splice(itemIndex, 0, newItem);

	return newArray;
};

export const removeArrayItem = <T>(list: Array<T>, index: number): Array<T> => {
	const newList = [...list];

	// Remove item from the index position
	newList.splice(index, 1);

	return newList;
};

export const ensureArray = <T>(value: T | T[] = []): T[] => [value].flat() as T[];
