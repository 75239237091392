import { UserItem } from '@kelvininc/node-client-sdk';

export const getUserDisplayName = (user: UserItem): string => {
	const { firstName, lastName, username } = user;

	if (!firstName) {
		return username;
	}

	return lastName ? `${firstName} ${lastName}` : firstName;
};

export const isKelvinUser = (email: string) =>
	email.includes('@kelvininc.com') || email.includes('@kelvin.ai');
