import { Env } from '@kelvininc/tsutils';

import { BuildAbsoluteURLParams } from '../../../types';

export const buildAbsoluteURL = ({
	nextUrl,
	basePath = Env.getString('BASE_PATH'),
	location = '/'
}: BuildAbsoluteURLParams) => {
	return `${nextUrl.origin}${basePath}${location}`;
};
