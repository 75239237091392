import { DataTag, EKrnResource, KvKRNParser } from '@kelvininc/node-client-sdk';
import { EDataTagSourceType, EDataTagType } from '@kelvininc/types';

export const getDataTagSourceType = (dataTag: DataTag): EDataTagSourceType => {
	const source = KvKRNParser.parseKRN(dataTag.source);

	if (EKrnResource.WorkloadAppVersion === source.resource) {
		return EDataTagSourceType.Application;
	}

	if (EKrnResource.User === source.resource) {
		return EDataTagSourceType.User;
	}

	if (EKrnResource.ServiceAccount === source.resource) {
		return EDataTagSourceType.ServiceAccount;
	}

	throw new Error(`Unexpected data tag source: ${dataTag.source}`);
};

export const getDataTagType = (dataTag: DataTag): EDataTagType =>
	dataTag.startDate.valueOf() === dataTag.endDate.valueOf()
		? EDataTagType.Point
		: EDataTagType.Period;
