import { IParamtersAppVersionSchemaGet } from '@kelvininc/node-client-sdk';
import { IFormData } from '@kelvininc/types';
import { get, isNil } from 'lodash-es';

export const getDefaultsFromSchema = (schema?: IParamtersAppVersionSchemaGet) => {
	const parameters = Object.entries(schema?.properties ?? {});

	return parameters.reduce<Record<string, IFormData>>((acc, [parameterName, parameter]) => {
		const defaultValue = get(parameter, 'default');
		if (!isNil(defaultValue)) {
			acc[parameterName] = defaultValue;
		}

		return acc;
	}, {});
};
