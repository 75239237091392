import {
	EKvHttpStatusCode,
	IKvHttpResponse,
	ResponseErrorObject
} from '@kelvininc/node-client-sdk';

import { get, isNil } from 'lodash-es';

import { ensureArray } from '../arrays';

import { IKvHttpMultiStatusResponse } from './types';

export const extractPayloadFromErrorResponse = <R>(
	response: ResponseErrorObject | ResponseErrorObject[],
	payloadKey: string = 'payload',
	defaultValue = ''
): R[] => {
	const errors = ensureArray(response);

	return errors.reduce<R[]>((accumulator, error: ResponseErrorObject) => {
		const payload = get(error, payloadKey, defaultValue) as R;

		if (!isNil(payload)) {
			accumulator.push(payload);
		}

		return accumulator;
	}, []);
};

export const extractErrorsByName = (
	error: IKvHttpResponse
): Record<string, ResponseErrorObject[]> => {
	const errors = ensureArray(error.httpResponse);

	return errors.reduce<Record<string, ResponseErrorObject[]>>(
		(acc, error: ResponseErrorObject) => {
			(acc[error.name] || (acc[error.name] = [])).push(error);

			return acc;
		},
		{}
	);
};

export const isHttpError = (
	error: unknown,
	status?: EKvHttpStatusCode
): error is IKvHttpResponse => {
	if (error === null || typeof error !== 'object' || !('status' in error)) {
		return false;
	}

	if (status) {
		return error.status === status;
	}

	return true;
};

export const isInvalidDataError = (error: unknown): boolean =>
	isHttpError(error) && error.httpResponse?.name === 'err_invalid_data';

export const isMultiStatusResponse = (response: unknown): response is IKvHttpMultiStatusResponse =>
	isHttpError(response, EKvHttpStatusCode.MultiStatus);
