import { UserMeGet } from '@kelvininc/node-client-sdk';
import { isKelvinUser } from '@kelvininc/tsutils';
import { PropsWithChildren, useEffect } from 'react';

import { getHeap } from './utils';

type HeapWrapperProps = {
	user: UserMeGet;
};

export const HeapAnalyticsWrapper = ({ children, user }: PropsWithChildren<HeapWrapperProps>) => {
	useEffect(() => {
		if (!user) return;

		const heap = getHeap();

		if (heap) {
			heap.identify(user.email);
			heap.addUserProperties({ isKelvinUser: isKelvinUser(user.email) });
		}
	}, [user]);

	return <>{children}</>;
};
