import {
	AssetDatastreamKRN,
	DataStream,
	DataStreamContext,
	EDataStreamType,
	EDataType,
	EKrnResource,
	ITimeseriesLastGetData,
	KvKRNParser
} from '@kelvininc/node-client-sdk';
import {
	EDataStreamTypes,
	IDataStreamAssetContext,
	IDatastreamDataTypeValues
} from '@kelvininc/types';

import { isBooleanish } from '../../booleans';

import { isNumberish } from '../../numbers';

export const isDataStreamDataTypeString = (dataType: unknown): dataType is EDataType.String =>
	dataType === EDataType.String;

export const isDataStreamDataTypeBoolean = (dataType: unknown): dataType is EDataType.Boolean =>
	dataType === EDataType.Boolean;

export const isDataStreamDataTypeObject = (dataType: unknown): dataType is EDataType.Object =>
	dataType === EDataType.Object;

export const isDataStreamDataTypeNumber = (dataType: unknown): dataType is EDataType.Number =>
	dataType === EDataType.Number;

export const isDataStreamMeasurement = (dataStream: DataStream): boolean =>
	dataStream.type === EDataStreamType.Measurement;

export const isDataStreamComputed = (dataStream: DataStream): boolean =>
	dataStream.type === EDataStreamType.Computed;

export const getAssetDatastreamKey = (assetName: string, datastreamName: string): string =>
	`${assetName}#${datastreamName}`;

export const fromAssetDatastreamKey = (
	assetDatastreamKey: string
): { assetName: string; datastreamName: string } => {
	const [assetName, datastreamName] = assetDatastreamKey.split('#');

	return { assetName, datastreamName };
};

// TODO: Understand why type lookup doesn't work here
export const parseDatastreamValue = <T extends keyof IDatastreamDataTypeValues>(
	value: string,
	dataType: T
): IDatastreamDataTypeValues[T] | undefined => {
	switch (true) {
		case isDataStreamDataTypeBoolean(dataType):
			return (isBooleanish(value) ? value === 'true' : undefined) as
				| IDatastreamDataTypeValues[T]
				| undefined;

		case isDataStreamDataTypeNumber(dataType):
			return (isNumberish(value) ? Number.parseFloat(value as string) : undefined) as
				| IDatastreamDataTypeValues[T]
				| undefined;

		default:
			return value as IDatastreamDataTypeValues[T];
	}
};

export const getDatastreamDataType = (dataType: string): EDataType | undefined => {
	if (isDataStreamDataTypeString(dataType)) {
		return EDataType.String;
	}

	if (isDataStreamDataTypeNumber(dataType)) {
		return EDataType.Number;
	}

	if (isDataStreamDataTypeBoolean(dataType)) {
		return EDataType.Boolean;
	}

	if (isDataStreamDataTypeObject(dataType)) {
		return EDataType.Object;
	}

	return;
};

export const buildDatastreamAssetContexts = (
	data: DataStreamContext[]
): IDataStreamAssetContext[] => {
	return data.reduce((acc, datastreamContext) => {
		const datastreamAssetsCtx = datastreamContext.context.map((ctx) => {
			const { resourceContent } = KvKRNParser.parseKRN<EKrnResource.Asset>(ctx.resource);
			return {
				datastreamName: datastreamContext.datastreamName,
				assetName: resourceContent.asset,
				writable: ctx.writable
			} as IDataStreamAssetContext;
		});
		return acc.concat(datastreamAssetsCtx);
	}, [] as IDataStreamAssetContext[]);
};

export const buildDatastreamAssetStorageSelectors = (
	dataStreams: IDataStreamAssetContext[]
): ITimeseriesLastGetData => ({
	selectors: dataStreams.map(({ datastreamName, assetName }) => ({
		resource: AssetDatastreamKRN.toKRN({
			asset: assetName,
			datastream: datastreamName
		})
	}))
});

// TODO: Remove old application IO data types in the future.
export const dataStreamTypeMapper = (type: EDataStreamTypes): EDataType => {
	const typeMap: Record<EDataStreamTypes, EDataType> = {
		[EDataStreamTypes.Boolean]: EDataType.Boolean,
		[EDataStreamTypes.RawBoolean]: EDataType.Boolean,
		[EDataStreamTypes.RawFloat32]: EDataType.Number,
		[EDataStreamTypes.RawFloat64]: EDataType.Number,
		[EDataStreamTypes.RawInt32]: EDataType.Number,
		[EDataStreamTypes.RawUint32]: EDataType.Number,
		[EDataStreamTypes.Number]: EDataType.Number,
		[EDataStreamTypes.RawText]: EDataType.String,
		[EDataStreamTypes.String]: EDataType.String,
		[EDataStreamTypes.Object]: EDataType.Object
	};

	if (!(type in typeMap)) {
		throw new Error(`Unsupported datastream type: ${type}`);
	}

	return typeMap[type];
};
