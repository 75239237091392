import {
	IPaginationCursorParams,
	IPaginationLimitsParams,
	IPaginationParams,
	KvPaginatorBase,
	KvPaginatorCursor,
	KvPaginatorLimits,
	Model,
	PaginatorRequest
} from '@kelvininc/node-client-sdk';

import { EMPTY, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { isHttpError } from '../../../http';

export abstract class CombinePaginationBase<
	T extends Model,
	K extends IPaginationParams,
	P extends KvPaginatorBase<T, K>
> {
	public abstract paginator: P;

	combineFetchAll() {
		const paginationError$ = this.paginator.getErrorStream().pipe(
			map((err) => {
				if (isHttpError(err)) {
					throw new Error(JSON.stringify(err));
				}

				return EMPTY;
			}),
			take(1)
		);

		return combineLatest([this.paginator.fetchAll(), paginationError$]).pipe(
			map(([data]) => data)
		);
	}
}

export class CombinePaginationLimits<
	T extends Model,
	K extends IPaginationLimitsParams
> extends CombinePaginationBase<T, K, KvPaginatorLimits<T, K>> {
	public paginator: KvPaginatorLimits<T, K>;

	constructor(requester: PaginatorRequest<T, K>, params: K) {
		super();
		this.paginator = new KvPaginatorLimits<T, K>(requester, params);
	}
}

export class CombinePaginationCursor<
	T extends Model,
	K extends IPaginationCursorParams
> extends CombinePaginationBase<T, K, KvPaginatorCursor<T, K>> {
	public paginator: KvPaginatorCursor<T, K>;

	constructor(requester: PaginatorRequest<T, K>, params: K) {
		super();
		this.paginator = new KvPaginatorCursor<T, K>(requester, params);
	}
}
