import { DataStream, EGuardrailRelativeValueType, Unit } from '@kelvininc/node-client-sdk';
import { DateInput } from '@kelvininc/types';

import { formatDatastreamValue, getUnitSymbol } from '../../decorators/datastream';
import { getDateTimeFromNowFormat } from '../../decorators/date';

export const formatStaticGuardrailValue = (
	value?: number,
	datastream?: DataStream,
	unit?: Unit
): string => {
	return formatDatastreamValue(value, datastream, getUnitSymbol(unit, datastream), true, 'N/A');
};

export const formatRelativeGuardrailValue = (
	type: EGuardrailRelativeValueType,
	value?: number,
	datastream?: DataStream,
	unit?: Unit
): string => {
	if (type === EGuardrailRelativeValueType.Percentage) {
		return `${value} %`;
	}

	return formatStaticGuardrailValue(value, datastream, unit);
};

export const formatGuardrailUpdatedAt = (updateAt: DateInput): string => {
	return updateAt ? getDateTimeFromNowFormat(updateAt) : 'N/A';
};
