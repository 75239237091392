import { AppVersion } from '@kelvininc/node-client-sdk';

import { IAppRegistryPayloadInputOutput } from '@kelvininc/types';
import { get } from 'lodash-es';

import { KELVIN_APP_PAYLOAD_INPUTS_PATH, KELVIN_APP_PAYLOAD_OUTPUTS_PATH } from './config';

export const getAppVersionInputs = (appVersion: AppVersion): IAppRegistryPayloadInputOutput[] =>
	get(appVersion, KELVIN_APP_PAYLOAD_INPUTS_PATH, []);

export const getAppVersionOutputs = (appVersion: AppVersion): IAppRegistryPayloadInputOutput[] =>
	get(appVersion, KELVIN_APP_PAYLOAD_OUTPUTS_PATH, []);
