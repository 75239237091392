import {
	IPaginationParams,
	Model,
	PaginationCursor,
	PaginationLimits
} from '@kelvininc/node-client-sdk';
import { KelvinPermission, MetadataVersionsInfo } from '@kelvininc/types';
import { NextURL } from 'next/dist/server/web/next-url';
import { useRouter } from 'next/navigation';
import { JWT } from 'next-auth/jwt';

declare module 'next-auth' {
	interface Session {
		token: JWT;
		user: {
			name: string;
			email: string;
		};
	}
}

declare module 'next-auth/jwt' {
	interface JWT {
		accessToken?: string;
		refreshToken?: string;
		idToken?: string;
		expiresIn?: number;
		date: number;
		permissions?: Record<KelvinPermission, boolean>;
	}
}

export enum AuthParams {
	CallbackUrl = 'callbackUrl',
	Error = 'error'
}

export type SSRPaginator<T extends Model, K extends IPaginationParams> = {
	data: T[];
	pagination: PaginationCursor | PaginationLimits;
	requestParams: K;
};

export type BaseAppSettings = {
	basePath?: string;
	loginPath: string;
	logoutPath: string;
	logoutUrl: string;
	accountUrl: string;
	symbolsFileName: string;
	issuerUrl: string;
	documentationLink: string;
	grafanaLink: string;
	auditLogsLink: string;
	supportLink: string;
	api: {
		url: string;
		swaggerUrl: string;
		client: string;
	};
	authentication: {
		realm: string;
		url: string;
		path: string;
	};
	versions: MetadataVersionsInfo;
	isProfessionalEdition: boolean;
};

export type AppSettingsExtender<S> = (appSettings: BaseAppSettings) => S;

export type RouterInstance = ReturnType<typeof useRouter>;
export type BuildAbsoluteURLParams = {
	nextUrl: NextURL;
	basePath?: string;
	location?: string;
};
