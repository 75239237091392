import { ControlChangeGet, EControlChangeState } from '@kelvininc/node-client-sdk';
import {
	EIconName,
	ETagState,
	ISelectMultiOptions,
	ITagStatus
} from '@kelvininc/react-ui-components';
import { ControlChangish } from '@kelvininc/types';

import { sortModels } from './models';

export const getControlChangeStatusName = (status: EControlChangeState): string =>
	({
		[EControlChangeState.Pending]: 'Pending',
		[EControlChangeState.Ready]: 'Ready',
		[EControlChangeState.Sent]: 'Sent',
		[EControlChangeState.Processed]: 'Processed',
		[EControlChangeState.Applied]: 'Applied',
		[EControlChangeState.Failed]: 'Failed',
		[EControlChangeState.Rejected]: 'Rejected'
	})[status];

export const getControlChangeStatusIconName = (status: EControlChangeState): EIconName =>
	({
		[EControlChangeState.Pending]: EIconName.Building,
		[EControlChangeState.Ready]: EIconName.Building,
		[EControlChangeState.Sent]: EIconName.Building,
		[EControlChangeState.Processed]: EIconName.Building,
		[EControlChangeState.Applied]: EIconName.Success,
		[EControlChangeState.Failed]: EIconName.Error,
		[EControlChangeState.Rejected]: EIconName.Error
	})[status];

export const getControlChangeStatusTagState = (status: EControlChangeState): ETagState =>
	({
		[EControlChangeState.Pending]: ETagState.Warning,
		[EControlChangeState.Ready]: ETagState.Warning,
		[EControlChangeState.Sent]: ETagState.Warning,
		[EControlChangeState.Processed]: ETagState.Warning,
		[EControlChangeState.Applied]: ETagState.Success,
		[EControlChangeState.Failed]: ETagState.Error,
		[EControlChangeState.Rejected]: ETagState.Error
	})[status];

export const getControlChangeTagStatus = (status: EControlChangeState): ITagStatus => ({
	state: getControlChangeStatusTagState(status),
	icon: getControlChangeStatusIconName(status),
	label: getControlChangeStatusName(status)
});

export const sortControlChanges = <T extends ControlChangish>(
	controlChanges: Array<T>,
	sortBy: keyof T = 'resource',
	asc?: boolean,
	comparator?: (modelA: T, modelB: T, sortBy: keyof T, asc: boolean) => number
): T[] => sortModels(controlChanges, sortBy, asc, comparator);

export const isControlChangeFinished = (controlChange: ControlChangeGet): boolean =>
	[
		EControlChangeState.Applied,
		EControlChangeState.Failed,
		EControlChangeState.Rejected
	].includes(controlChange.lastState as EControlChangeState);

export const CONTROL_CHANGES_STATUSES_DROPDOWN_OPTIONS: ISelectMultiOptions = {
	[EControlChangeState.Pending]: {
		value: EControlChangeState.Pending,
		label: getControlChangeStatusName(EControlChangeState.Pending)
	},
	[EControlChangeState.Rejected]: {
		value: EControlChangeState.Rejected,
		label: getControlChangeStatusName(EControlChangeState.Rejected)
	},
	[EControlChangeState.Ready]: {
		value: EControlChangeState.Ready,
		label: getControlChangeStatusName(EControlChangeState.Ready)
	},
	[EControlChangeState.Sent]: {
		value: EControlChangeState.Sent,
		label: getControlChangeStatusName(EControlChangeState.Sent)
	},
	[EControlChangeState.Processed]: {
		value: EControlChangeState.Processed,
		label: getControlChangeStatusName(EControlChangeState.Processed)
	},
	[EControlChangeState.Applied]: {
		value: EControlChangeState.Applied,
		label: getControlChangeStatusName(EControlChangeState.Applied)
	},
	[EControlChangeState.Failed]: {
		value: EControlChangeState.Failed,
		label: getControlChangeStatusName(EControlChangeState.Failed)
	}
};
