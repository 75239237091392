import { useEffect } from 'react';

export interface IVersionLoggerProps {
	appName: string;
	version: string;
}

export const VersionLogger = ({ appName, version }: IVersionLoggerProps) => {
	useEffect(() => {
		console.log(`${appName} v${version}`);
	}, [appName, version]);

	return null;
};
