import { buildUrl } from '@kelvininc/tsutils';
import {
	SessionProvider as NextAuthSessionProvider,
	SessionProviderProps as NextAuthSessionProviderProps
} from 'next-auth/react';
import {
	Context,
	FC,
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useState
} from 'react';
import urljoin from 'url-join';

import { LOGOUT_URL } from './config';
import { SessionContextValues } from './types';

export const SessionContext: Context<null | SessionContextValues> =
	createContext<null | SessionContextValues>(null);

export const SessionProvider: FC<PropsWithChildren<NextAuthSessionProviderProps>> = ({
	children,
	basePath,
	session,
	...otherProps
}) => {
	const [isSigningOut, setSigningOut] = useState(false);

	if (basePath) {
		basePath = urljoin(basePath, '/api/auth');
	}

	return (
		<SessionContext.Provider value={{ isSigningOut, setSigningOut }}>
			<NextAuthSessionProvider session={session} basePath={basePath} {...otherProps}>
				{children}
			</NextAuthSessionProvider>
		</SessionContext.Provider>
	);
};

export const useSessionProvider = (): SessionContextValues => {
	const session = useContext(SessionContext);

	if (!session) {
		throw new Error('useSessionProvider must be used within a SessionProvider');
	}

	return session;
};

export const useLogout = (): (() => void) => {
	const session = useSessionProvider();

	const logout = useCallback(() => {
		session.setSigningOut(true);
		const logoutUrl = buildUrl(LOGOUT_URL, {
			redirectUri: window.location.href
		});

		window.location.href = logoutUrl;
	}, [session]);

	return logout;
};
