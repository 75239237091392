import { Asset, EKrnResource, KvKRNParser } from '@kelvininc/node-client-sdk';

export const assetNameFromResource = (resource: string): string => {
	const {
		resourceContent: { asset: assetName }
	} = KvKRNParser.parseKRN<EKrnResource.Asset>(resource);

	return assetName;
};

export const getAssetTitle = (assetName: string, assetsMap: Record<string, Asset>): string => {
	const asset = assetsMap[assetName];

	return asset?.title ?? assetName;
};
