import { Asset, AssetType, EAssetState } from '@kelvininc/node-client-sdk';

import { EIconName, ETagState, ITagStatus } from '@kelvininc/react-ui-components';

import { FilterPredicate, buildPredicates } from '../../filters';
import { searchObjects } from '../../objects';

export const oneOfAssetNamesFilter =
	<T extends Asset | Asset>(assetNames: string[]): FilterPredicate<T> =>
	({ name }) =>
		assetNames.includes(name);

export const oneOfAssetTypesFilter =
	<T extends Asset | Asset>(assetTypes: string[]): FilterPredicate<T> =>
	({ assetTypeName }) =>
		assetTypes.includes(assetTypeName);

export const filterAssetList = (
	search: string[] = [],
	assetNames: string[] = [],
	assetTypes: string[] = [],
	data: Asset[]
): Asset[] => {
	const predicates: FilterPredicate<Asset>[] = [];
	if (assetNames.length > 0) {
		predicates.push(oneOfAssetNamesFilter(assetNames));
	}

	if (assetTypes.length > 0) {
		predicates.push(oneOfAssetTypesFilter(assetTypes));
	}

	const filteredData = data.filter(buildPredicates(...predicates));

	if (search.length > 0) {
		return searchObjects(search, ['name', 'title'], filteredData);
	}

	return filteredData;
};

export const getAssetType = (asset: Asset): AssetType => {
	return new AssetType({
		name: asset.assetTypeName,
		title: asset.assetTypeTitle
	});
};

export const getAssetStatusName = (status: EAssetState): string =>
	({
		[EAssetState.Offline]: 'Offline',
		[EAssetState.Online]: 'Online',
		[EAssetState.Unknown]: 'Unknown'
	})[status];

export const getAssetStatusIconName = (status: EAssetState): EIconName =>
	({
		[EAssetState.Offline]: EIconName.CloseCircle,
		[EAssetState.Online]: EIconName.Success,
		[EAssetState.Unknown]: EIconName.CloseCircle
	})[status];

export const getAssetStatusTagState = (status: EAssetState): ETagState =>
	({
		[EAssetState.Offline]: ETagState.Unknown,
		[EAssetState.Online]: ETagState.Success,
		[EAssetState.Unknown]: ETagState.Unknown
	})[status];

export const getAssetTagStatus = (status: EAssetState): ITagStatus => ({
	state: getAssetStatusTagState(status),
	icon: getAssetStatusIconName(status),
	label: getAssetStatusName(status)
});

export const ASSET_STATUSES_FILTER_OPTIONS = [
	{
		title: getAssetStatusName(EAssetState.Offline),
		const: EAssetState.Offline
	},
	{
		title: getAssetStatusName(EAssetState.Online),
		const: EAssetState.Online
	},
	{
		title: getAssetStatusName(EAssetState.Unknown),
		const: EAssetState.Unknown
	}
];

export const ASSETS_STATUS_INLINE_FILTER_NAME = 'status';
