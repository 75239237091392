import { ControlChangeGet, EControlChangeState } from '@kelvininc/node-client-sdk';

export const getWorkloadLink = (createdBy: string, baseroute = ''): string => {
	const [nodeName, workloadName] = createdBy.split('/');

	return `${baseroute}/nodes/${nodeName}/workloads/${workloadName}`;
};

export const isLastStateFailed = (controlChange: ControlChangeGet): boolean =>
	controlChange.lastState === EControlChangeState.Failed ||
	controlChange.lastState === EControlChangeState.Rejected;
