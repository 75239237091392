import {
	DataStream,
	EDatastreamExtraFieldComputationDataAgg,
	Unit
} from '@kelvininc/node-client-sdk';
import { ISelectSingleOptions } from '@kelvininc/react-ui-components';
import {
	DataStreamAggregationFunction,
	DatastreamDataTypeValue,
	EDataStreamCustomAgg
} from '@kelvininc/types';
import { get, isNil, round } from 'lodash-es';

import { isDataStreamDataTypeNumber } from '../models/datastreams';
import { isExponential, isNumberish } from '../numbers';

export const formatDatastreamValue = (
	value: DatastreamDataTypeValue | undefined,
	datastream?: DataStream,
	unitSymbol = '',
	withDisplayUnit = true,
	emptyValue = '—'
): string => {
	if (isNil(value) || !datastream || !value.toString()) {
		return emptyValue;
	}

	if (isNumberish(value) && isDataStreamDataTypeNumber(datastream.dataTypeName)) {
		const numericValue = Number(value);
		const roundedValue = isExponential(numericValue)
			? numericValue.toPrecision(3)
			: round(numericValue, 2);
		const displayUnit = unitSymbol;

		const displayValue = `${roundedValue}${
			withDisplayUnit && displayUnit ? ` ${displayUnit}` : ''
		}`;

		return displayValue;
	}

	return `${value}`;
};

export const getDatastreamUnitSymbol = (datastream: DataStream, unitsDic: Record<string, Unit>) => {
	const unit = get(unitsDic, [datastream?.unitName]);
	return getUnitSymbol(unit, datastream);
};

export const getUnitSymbol = (unit?: Unit, datastream?: DataStream): string =>
	unit?.symbol ?? datastream?.unitName ?? '';

export const getUnitDisplayLabel = (unit?: Unit): string => {
	if (!unit) return '';

	const { title, symbol } = unit;

	return `${title ?? ''} ${symbol ? `(${symbol})` : ''}`.trim();
};

export const getDatastreamAggregationName = (aggregation: DataStreamAggregationFunction): string =>
	({
		[EDatastreamExtraFieldComputationDataAgg.Count]: 'Count',
		[EDatastreamExtraFieldComputationDataAgg.Mean]: 'Mean',
		[EDatastreamExtraFieldComputationDataAgg.Sum]: 'Sum',
		[EDatastreamExtraFieldComputationDataAgg.Max]: 'Max',
		[EDatastreamExtraFieldComputationDataAgg.Min]: 'Min',
		[EDataStreamCustomAgg.Last]: 'Last'
	})[aggregation];

export const AGGREGATION_FUNCTION_DROPDOWN_OPTIONS: ISelectSingleOptions = {
	[EDatastreamExtraFieldComputationDataAgg.Count]: {
		value: EDatastreamExtraFieldComputationDataAgg.Count,
		label: getDatastreamAggregationName(EDatastreamExtraFieldComputationDataAgg.Count)
	},
	[EDatastreamExtraFieldComputationDataAgg.Mean]: {
		value: EDatastreamExtraFieldComputationDataAgg.Mean,
		label: getDatastreamAggregationName(EDatastreamExtraFieldComputationDataAgg.Mean)
	},
	[EDatastreamExtraFieldComputationDataAgg.Sum]: {
		value: EDatastreamExtraFieldComputationDataAgg.Sum,
		label: getDatastreamAggregationName(EDatastreamExtraFieldComputationDataAgg.Sum)
	},
	[EDatastreamExtraFieldComputationDataAgg.Max]: {
		value: EDatastreamExtraFieldComputationDataAgg.Max,
		label: getDatastreamAggregationName(EDatastreamExtraFieldComputationDataAgg.Max)
	},
	[EDatastreamExtraFieldComputationDataAgg.Min]: {
		value: EDatastreamExtraFieldComputationDataAgg.Min,
		label: getDatastreamAggregationName(EDatastreamExtraFieldComputationDataAgg.Min)
	},
	[EDataStreamCustomAgg.Last]: {
		value: EDataStreamCustomAgg.Last,
		label: getDatastreamAggregationName(EDataStreamCustomAgg.Last)
	}
};

export const NON_NUMERIC_AGGREGATION_FUNCTION_DROPDOWN_OPTIONS: ISelectSingleOptions = {
	[EDatastreamExtraFieldComputationDataAgg.Count]:
		AGGREGATION_FUNCTION_DROPDOWN_OPTIONS[EDatastreamExtraFieldComputationDataAgg.Count],
	[EDataStreamCustomAgg.Last]: AGGREGATION_FUNCTION_DROPDOWN_OPTIONS[EDataStreamCustomAgg.Last]
};
