import { initialize as initializeKelvinReactComponents } from '@kelvininc/react-ui-components';
import { useEffect } from 'react';
import urlJoin from 'url-join';

export type KelvinUIComponentsConfigOptions = {
	basePath?: string;
	symbolsFileName: string;
};

export const useKelvinUiComponentsConfig = ({
	basePath = '/',
	symbolsFileName
}: KelvinUIComponentsConfigOptions) => {
	useEffect(() => {
		const baseAssetsUrl = basePath ? urlJoin(basePath, '/') : undefined;

		initializeKelvinReactComponents({ baseAssetsUrl, symbolsFileName });
	}, [basePath, symbolsFileName]);
};
