import { default as dayjs } from 'dayjs';

export type SelectedRange = [] | [string] | [string, string];

export enum ECalendarAdvanceTimeType {
	Absolute = 'absolute',
	Relative = 'relative'
}

export type ICalendarAdvanceTime = {
	key: string;
	range: SelectedRange;
};

export interface ICalendarAdvanceSelectedTime {
	type: ECalendarAdvanceTimeType;
	key?: string;
}

export interface ICalendarAdvanceTimeChange {
	type: ECalendarAdvanceTimeType;
	payload?: ICalendarAdvanceTime;
}

export interface ITimeChange {
	time: ICalendarAdvanceTimeChange;
	timezone: ICalendarTimezone;
}

export interface ITimezoneOffset {
	name: string;
	offset: number;
	label: string;
}

export type ICalendarTimezone = Omit<ITimezoneOffset, 'label'>;

export enum ERelativeTimeRangeKey {
	Last_5_M = 'last-5-m',
	Last_10_M = 'last-10-m',
	Last_15_M = 'last-15-m',
	Last_30_M = 'last-30-m',
	Last_1_H = 'last-1-h',
	Last_6_H = 'last-6-h',
	Last_12_H = 'last-12-h',
	Last_24_H = 'last-24-h',
	Last_48_H = 'last-48-h',
	Last_72_H = 'last-72-h',
	Last_7_D = 'last-7-d',
	Last_14_D = 'last-14-d',
	Last_90_D = 'last-90-d',
	Last_2_W = 'last-2-w',
	Last_1_M = 'last-1-m',
	Next_5_M = 'next-5-m',
	Next_10_M = 'next-10-m',
	Next_15_M = 'next-15-m',
	Next_30_M = 'next-30-m',
	Next_1_H = 'next-1-h',
	Next_6_H = 'next-6-h',
	Next_12_H = 'next-12-h',
	Next_24_H = 'next-24-h',
	Next_48_H = 'next-48-h',
	Next_72_H = 'next-72-h',
	Next_7_D = 'next-7-d',
	Next_2_W = 'next-2-w',
	Next_1_M = 'next-1-m'
}

export type DateInput = dayjs.ConfigType;
