import { ParamDeserializer, ParamValueDeserializer, ParamValueSerialized } from '../types';

export const defaultParamValueDeserializer = <T>(param: string): T | undefined => {
	if (param === undefined || param.length === 0) {
		return;
	}

	try {
		// it will work for both booleans and numbers
		return JSON.parse(param);
	} catch (error) {
		// for strings it will throw an syntax error
		return param as T;
	}
};

export const deserializeParamArray = <K>(
	param: string[],
	deserializer: ParamValueDeserializer<K> = defaultParamValueDeserializer
): K[] | undefined => {
	const deserializedArray = param.reduce<K[]>((accumulator, value) => {
		const deserializedValue = deserializer(value);

		if (deserializedValue) {
			accumulator.push(deserializedValue);
		}

		return accumulator;
	}, []);

	if (deserializedArray.length > 0) {
		return deserializedArray;
	}
};

export const defaultParamDeserializer = <T>(
	param: ParamValueSerialized,
	deserializer: ParamValueDeserializer<T> = defaultParamValueDeserializer
): T | undefined => {
	if (Array.isArray(param)) {
		return deserializeParamArray(param, deserializer) as T;
	}

	return deserializer(param);
};

export const deserializeParam = <K>(
	paramValue: ParamValueSerialized,
	deserializer: ParamDeserializer<K> = defaultParamDeserializer
): K | undefined => {
	return deserializer(paramValue);
};
