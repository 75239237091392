import { KvLoader } from '@kelvininc/react-ui-components';
import { PropsWithChildren, useEffect, useState } from 'react';

import { registerKelvinModelsInSuperjson } from '../../../shared';

export const RegisterKelvinModels = ({ children }: PropsWithChildren) => {
	const [superjsonLoaded, setLoaded] = useState(false);

	useEffect(() => {
		registerKelvinModelsInSuperjson().then(() => setLoaded(true));
	}, []);

	if (!superjsonLoaded) {
		return <KvLoader />;
	}

	return <>{children}</>;
};
