import { isBoolean } from '../../booleans';
import { isNumber } from '../../numbers';
import { isString } from '../../strings';
import { ParamSerializer, ParamValueSerialized, ParamValueSerializer } from '../types';

const defaultParamValueSerializer = <V>(param: V): string | undefined => {
	if (param === undefined) {
		return;
	}

	if (isString(param) || isNumber(param) || isBoolean(param)) {
		const serializedValue = param.toString().trim();
		if (serializedValue.length === 0) {
			return;
		}

		return serializedValue;
	}

	throw Error('Generic type should have a proper serializer');
};

const serializeParamArray = <V>(
	param: V[],
	serializer: ParamValueSerializer<V> = defaultParamValueSerializer
): string[] | undefined => {
	const serializedArray = param.reduce<string[]>((accumulator, value) => {
		const serializedValue = serializer(value);

		if (serializedValue) {
			accumulator.push(serializedValue);
		}

		return accumulator;
	}, []);

	if (serializedArray.length > 0) {
		return serializedArray;
	}
};

const defaultParamSerializer = <V>(
	param: V,
	serializer: ParamValueSerializer<V> = defaultParamValueSerializer
): ParamValueSerialized | undefined => {
	if (param === undefined) {
		return;
	}

	if (Array.isArray(param)) {
		return serializeParamArray(param, serializer);
	}

	return serializer(param);
};

export const serializeParam = <V>(
	param: V,
	serializer: ParamSerializer<V> = defaultParamSerializer
): ParamValueSerialized | undefined => {
	return serializer(param);
};
