import { EPropertyType } from '@kelvininc/node-client-sdk';

export const isPropertyPrimitiveTypeString = (
	primitiveType: unknown
): primitiveType is EPropertyType.String => primitiveType === EPropertyType.String;

export const isPropertyPrimitiveTypeBoolean = (
	primitiveType: unknown
): primitiveType is EPropertyType.Boolean => primitiveType === EPropertyType.Boolean;

export const isPropertyPrimitiveTypeNumber = (
	primitiveType: unknown
): primitiveType is EPropertyType.Number => primitiveType === EPropertyType.Number;

export const isPropertyPrimitiveTypeTimestamp = (
	primitiveType: unknown
): primitiveType is EPropertyType.Timestamp => primitiveType === EPropertyType.Timestamp;
