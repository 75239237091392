import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

/** Source: https://github.com/remix-run/react-router/blob/main/packages/router/utils.ts#L950 */
const compilePath = (path: string, caseSensitive = false, end = true): RegExp => {
	let regexpSource =
		'^' +
		path
			.replace(/\/*\*?$/, '') // Ignore trailing / and /*, we'll handle it below
			.replace(/^\/*/, '/') // Make sure it has a leading /
			.replace(/[\\.*+^${}|()[\]]/g, '\\$&') // Escape special regex chars
			.replace(/\/:(\w+)(\?)?/g, (_: string, _paramName: string, isOptional) => {
				return isOptional ? '/?([^\\/]+)?' : '/([^\\/]+)';
			});

	if (path.endsWith('*')) {
		regexpSource +=
			path === '*' || path === '/*'
				? '(.*)$' // Already matched the initial /, just match the rest
				: '(?:\\/(.+)|\\/*)$'; // Don't include the / in params["*"]
	} else if (end) {
		// When matching to the end, ignore trailing slashes
		regexpSource += '\\/*$';
	} else if (path !== '' && path !== '/') {
		// If our path is non-empty and contains anything beyond an initial slash,
		// then we have _some_ form of path in our regex, so we should expect to
		// match only if we find the end of this path segment.  Look for an optional
		// non-captured trailing slash (to match a portion of the URL) or the end
		// of the path (if we've matched to the end).  We used to do this with a
		// word boundary but that gives false positives on routes like
		// /user-preferences since `-` counts as a word boundary.
		regexpSource += '(?:(?=\\/|$))';
	}

	return new RegExp(regexpSource, caseSensitive ? undefined : 'i');
};

export const useMatch = (pattern: string) => {
	const pathname = usePathname();

	return useMemo(() => {
		const matcher = compilePath(pattern);

		return pathname.match(matcher);
	}, [pathname, pattern]);
};
