import AbortablePromise from 'promise-abortable';

export const newInterval = (
	callback: (args: void) => void,
	ms: number
): ReturnType<typeof setInterval> | undefined => {
	if (ms <= 0) {
		return;
	}

	return setInterval(callback, ms);
};

export const cancelableDelayMilliseconds = <T = unknown>(ms: number): AbortablePromise<T> => {
	return new AbortablePromise((resolve, _reject, signal) => {
		const timeoutId = setTimeout(resolve, ms);
		signal.onabort = () => {
			clearTimeout(timeoutId);
		};
	});
};
