import { isNumberish } from '../numbers';

export const isString = (string: unknown): string is string =>
	string !== undefined && typeof string === 'string';

export const isEnumValue = <T extends object>(value: unknown, enumObject: T): boolean => {
	return Object.values(enumObject).includes(value);
};

export const isEnumKey = <T extends object>(value: unknown, enumObject: T): boolean => {
	return isString(value) && Object.keys(enumObject).includes(value as string);
};

export const getFirstLetter = ([firstLetter] = ''): string => {
	if (!firstLetter) {
		return '';
	}

	return firstLetter.toLocaleUpperCase();
};

export const capitalize = (string = ''): string => getFirstLetter(string) + string.slice(1);

export const convertToValidName = (string = '') => {
	return string
		.replace(/\s+/g, '-')
		.replace(/^[0-9\-\_]*/g, '')
		.replace(/[^a-zA-Z0-9\-\_]/g, '')
		.replace(/[\-\_]*$/g, '')
		.toLowerCase();
};

export const convertToValidTitle = (string = '') => {
	return string
		.replace(/\-|\_|\./g, ' ')
		.replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
};

export const capitalizeFirstLetter = (string = '') =>
	string.charAt(0).toUpperCase() + string.slice(1);

export const convertToValidSearch = (string?: string) => {
	return string?.replaceAll(',', ' ').trim() || undefined;
};

export const compareStrings = (stringA: string, stringB: string, asc = true): number => {
	const compareResult = stringA.localeCompare(stringB);

	if (!asc) {
		return compareResult * -1;
	}

	return compareResult;
};

export const compareVersionStrings = (stringA: string, stringB: string, asc = true) => {
	const comparisonResult = stringA.localeCompare(stringB, undefined, { numeric: true });

	return asc ? comparisonResult : comparisonResult * -1;
};

export const isSubString = (term: string, string: string, caseSensitive = false): boolean => {
	if (term.length === 0) {
		return true;
	}

	if (caseSensitive) {
		return string.includes(term);
	}

	return string.toLocaleLowerCase().includes(term.toLocaleLowerCase());
};

// User Input String Cleanup
// return string or a nullish value specified by the user
export const userInputStringCleanup = (string = '', nullishValue?: string) => {
	// Trim leading and trailing whitespace
	const str = string.trim();
	return str || nullishValue;
};

export const joinWithComma = (words: string[] | undefined | null): string =>
	(words ?? []).join(', ');

export const splitByComma = (string: string): string[] => string.split(', ');

export const buildUniqueName = (
	initial: string,
	inUse: string[],
	separator = ' ',
	caseSensitive = false,
	iteration = 0,
	iterationCountWrapper = (iterationValue: number) => `(${iterationValue})`
): string => {
	const name =
		iteration > 0 ? `${initial}${separator}${iterationCountWrapper(iteration)}` : initial;

	const isUnique = !inUse.some((item) => {
		if (caseSensitive) {
			return item === name;
		}

		return item.toLocaleLowerCase() === name.toLocaleLowerCase();
	});

	if (isUnique) {
		return name;
	}

	return buildUniqueName(
		initial,
		inUse,
		separator,
		caseSensitive,
		iteration + 1,
		iterationCountWrapper
	);
};

export const stringToNumber = (value: string): number => {
	if (!isNumberish(value)) {
		throw new Error('Invalid number');
	}

	return Number.parseFloat(value);
};
